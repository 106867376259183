import apiCall from "../../../../../common/apiCall";
import {dayDifference, getAggregationLevel, getFormattedDate} from "../../../../../common/formatters";
import {getDepartments} from "../../../../../common/utils";

export const emptyMessage = "KPI Metrics currently unavailable"

export const kpis = [
	{
		title: "Total Sessions",
		accessor: "totalSessions",
		duration: 1,
		units: null,
	},
	{
		title: "Total Pageviews",
		accessor: "totalPageviews",
		duration: 1.5,
		units: null,
	},
	{
		title: "Total Streams",
		accessor: "totalStreams",
		duration: 2,
		units: null,
	},
	{
		title: "Average Time Spent",
		accessor: "avgTimeSpent",
		duration: 2.5,
		units: "s",
	}
];

export const kpiGroupApi = (dateRange, department, departments, setDataInfo) => {
	setDataInfo((prevState) => ({...prevState, loading: true, empty: true}))

	let startDate, endDate, aggregationLevel, query;
	startDate = getFormattedDate(dateRange[0].startDate)
	endDate = getFormattedDate(dateRange[0].endDate)
	aggregationLevel = getAggregationLevel(dateRange[0].startDate, dateRange[0].endDate);

	query = `
			query keyMetrics($startDate: String!, $endDate: String!, $aggregationLevel: String!, $department: [String!]!) {
				keyMetrics(startDate: $startDate, endDate: $endDate, aggregationLevel: $aggregationLevel, department: $department) {
					kpi {
						totalSessions
						totalPageviews
						totalStreams
						avgTimeSpent
					}
				}
			}
		`;

	const queryVariable = {
		startDate: startDate,
		endDate: endDate,
		aggregationLevel: aggregationLevel,
		department: getDepartments(department, departments)
	};

	apiCall(query, queryVariable)
		.then(response => {
			const {keyMetrics: {kpi: data}} = response;

			if (Object.keys(data).length === 0) {
				throw new Error("Key Metrics query returned an empty object")
			}

			const kpiSum = Object.values(data).reduce((a, b) => a + b, 0);
			kpiSum > 0 ? setDataInfo({data: data, loading: false, empty: false}) :
				setDataInfo({data: data, loading: false, empty: true})

		})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: false, empty: true}))
			console.log({kpiError: error});
		});
}