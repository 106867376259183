import React from "react";
import {Divider, ListSubheader, MenuItem} from "@mui/material";

export const renderSelect = (values) => {
	return values.map((item, index) => {
		return (<MenuItem key={item.id + item.name + index} value={item.id}> {item.name} </MenuItem>)
	})
}

export const renderGroupedSelect = (headerName, headerValues) => {
	const items = renderSelect(headerValues)
	return [<Divider/>, <ListSubheader disableSticky={true}>{headerName}</ListSubheader>, <Divider/>, items]
}