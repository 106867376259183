export const searchInputStyle = {
	paper: {
		paddingX: 0.5,
		display: "flex",
		alignItems: "center",
		width: "100%",
		borderColor: "grey.400",
		borderWidth: "thin",
		"&:hover": {
			borderColor: "secondaryColour.main",
		},
	},
	button: {padding: 0.75},
	input: {
		mx: 1,
		flex: 1,
	}
}