import { createGlobalStyle } from "styled-components";

import { BG_COLOR, TEXT_COLOR } from "./common/colors";

export const contentMaxWidth = 1900;

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;;
    font-size: 12pt;
    background-color: ${BG_COLOR};
    color: ${TEXT_COLOR};
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
