import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {Box, Grid, Typography} from "@mui/material";
import { chartOverlayMessage } from "./ChartOverlayHelper";
import { chartOverlayStyle } from "./ChartOverlayStyle";

export const ChartOverlay = () => {
    return (<Box
    sx={chartOverlayStyle.opaqueBox}
>
    <Box
    sx={chartOverlayStyle.alertBox}
        >
        <InfoOutlinedIcon sx={chartOverlayStyle.icon}/>
        <Typography sx={chartOverlayStyle.text}>
            {chartOverlayMessage}
        </Typography>
    </Box>
</Box>)}