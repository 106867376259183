import apiCall from "../../../../common/apiCall";
import {getFormattedDate} from "../../../../common/formatters";
import {getDepartments} from "../../../../common/utils";

export const sectionTitle = "Subjects"
export const sections = [{id: "All Sections", name: "All Subjects"}, {id: "top", name: "Top Performing Subjects"}, {id: "bottom", name: "Bottom Performing Subjects"}]
export const mediaTitle = "Content Types"
export const media = [{id: "All Media", name: "All Content Types"}, {id: "Live Video", name: "Live Video"},
	{id: "On-Demand Video", name: "On-Demand Video"}, {id: "Standard Article", name: "Standard Article"}]

export const defaultSectionValue = "All Subjects"
export const emptyMessage = "Top performer data currently unavailable"
export const title = "What resonates with our audiences?"

export const tagStatsApi = (dateRange, department, departments, sectionFilter, mediaFilter, setDataInfo, setEmpty, setHasError) => {
	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	let startDate, endDate, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])

	query = `
			query tagMainTable($startDate: String!, $endDate: String!, $department: [String!]!, $contentType:[String!]!) {
				tagMainTable(startDate: $startDate, endDate: $endDate, department: $department, contentType: $contentType) {
					tableData {
                        tag
                        numContent
                        loads
                        streams
                        pageViews
                        tagPopularity
                        tagUtilization
                        averagePageViews
						zone
						commonlyUsedWith
						topArticles {
							contentId
							contentHeadline
							publishTime
							pageViews
							contentType
							contentSubtype
						}
					}
				}
			}
		`;
	const queryVariables = {
		startDate: startDate,
		endDate: endDate,
		department: getDepartments(department, departments),
		contentType: [mediaFilter.id]
	};

	apiCall(query, queryVariables).then(response => {
		const {tagMainTable: {tableData: data}} = response;
		const filteredData = data
		.filter(row => sectionFilter.id === "All Sections"|| row.zone === sectionFilter.id)
		.map(row=> {
			const tags = row.commonlyUsedWith === "null" ? "n/a" : JSON.parse(row.commonlyUsedWith).map(item => item.tags_used_with).join(', ');
			return {...row, commonlyUsedWith: tags, dateRange:dateRange }
		})

        setDataInfo({
			data: filteredData,
			loading: false,
			error:false
		})
		if (filteredData.length == 0){
			setEmpty(true)
		}
	})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
			console.log({tagStatsError: error});
		});


}