import React from "react"
import {Route} from "react-router-dom"
import {useAuth} from "./firebase/context";
import {Login} from "./components/v5/Pages/Login/Login";
import {LogoProgress} from "./components/v5/Loading/Progress/LogoProgress/LogoProgress";
import {ID_TOKEN_STORAGE} from "./common/variables";

export const PrivateRoute = ({path, children}) => {
    const {loading, isAuth} = useAuth()
    const isValid = localStorage.getItem(ID_TOKEN_STORAGE)

    if (loading && !isAuth) {
        return <LogoProgress overlay={true}/>
    }

    return (
        <Route path={path}>
            {!loading && isValid ? children : <Login/>}
        </Route>
    )
}