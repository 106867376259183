export const logOutButtonStyle = {
        button: {
        "& .MuiSvgIcon-root": {
            color: "common.black",
        },
        "& .MuiButton-startIcon": {
            marginLeft: 2, 
        },
        color: "common.black",
        backgroundColor: "common.white",
        height:"52px", width:"220px", 
        justifyContent: "flex-start", 
        textAlign: "left",
    }
}