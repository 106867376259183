import React from "react";
import {DataGridPremium,} from "@mui/x-data-grid-premium";
import { tagStatsColumns } from "./TagStatsTableHelper";
import {tablesStyle} from "../TablesStyle";
import {ColumnEditIcon, columnLocalText, TableToolbar} from "../TableToolbar/TableToolbar";
import { tagStatsTableStyle } from "./TagStatsTableStyle";
import { DetailPanelContent } from "./DetailPanel/DetailPanelContent/DetailPanelContent";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"

export const TagStatsTable = ({tagStatsData, columnVisibilityModel, setColumnVisibilityModel, dateRange, department}) => {
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			columns={tagStatsColumns}
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={(newModel) =>
				setColumnVisibilityModel(newModel)
			}
			rows={tagStatsData}
			components={{
				ColumnSelectorIcon: ColumnEditIcon, Toolbar: TableToolbar, noRowsOverlay: CustomNoRowsOverlay
			}}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar, placement: "bottom-end"},
				toolbar: {table: "TagStatisticsTable", dateRange: dateRange, department: department}
			}}
			localeText={{toolbarColumns: columnLocalText}}
			initialState={{
				pagination: {
					paginationModel: {pageSize: 10, page: 0},
				},
			}}
			pageSizeOptions={[5, 10, 20]}
			pagination
			getDetailPanelContent={DetailPanelContent}
			columnHeaderHeight={38}
			rowHeight={tagStatsData.length === 0 ? 76 : 57}
			autoHeight={true}
			disableRowSelectionOnClick={true}
            getRowId={(row) => row.pageViews + "-" + row.tag}
			sx={tagStatsTableStyle.dataGrid}
		/>
	);
}