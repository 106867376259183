export const chartOverlayStyle = {
    opaqueBox: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        zIndex: 10,
        },
        alertBox: {
            padding: "10px 20px",
            border: "1px solid blueInfo", 
            borderRadius: "4px",
            typography:"text.body1",
            backgroundColor: "white", // adjust background color
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            display:"flex",
            flexDirection:"row"
            },
            icon: {color:"blueInfo", paddingRight:1},
            text: {typography:"text.body1", color:"blueInfo"}
}