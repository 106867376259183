import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import { renderVideoCountStyle } from "./RenderVideoCountStyle";

export const RenderVideoCount= (params) => {
    const videoCount = params?.value?.embeddedVideo.length
	return (
        <Typography sx={renderVideoCountStyle.string}>
            {videoCount}
		</Typography>
    )
}