export const graphOverviewStyle = {
	hide: {
		[(theme) => theme.breakpoints.down("sm")]: {
			display: "none"
		},
	},
	labels: {
		paddingTop: 1
	},
	paperOverview: {
		padding: 2
	},
	kpiTitle: {
		typography: "text.body2",
		fontWeight: 400
	},
	kpiNumeric: {
		typography: "numeric.h5"
	},
	kpiTooltip: {
		typography: "text.tooltip",
		paddingLeft: 0.5,
		fontWeight: 400
	},
	kpiLegend: {
		width: 14,
		height: 15,
		borderRadius: 3
	}
}