import * as React from 'react';
import {Grid} from "@material-ui/core";

export const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <>
            {value === index && (
                <Grid container item justifyContent='center'>
                    {children}
                </Grid>
            )}
        </>
    );
}