import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {handleApply, handleClear, handleKeyDownApply, handleKeyDownClear, handleKeyDownClose, handleKeyDownEscape} from "../ActionBar/ActionBarHelper"
import {actionBarStyle} from "../ActionBar/ActionBarStyle"
import {Grid} from "@mui/material";
import { useEffect } from 'react';

export const ActionBar = ({setAlert, alert, setDateRange, dateRange, storageKey, setOpen, setEmpty, setHasError}) => {
	useEffect(()=>{
		document.addEventListener("keydown", (event) => handleKeyDownEscape(event, setOpen));
		return(()=>{
			document.removeEventListener("keydown", (event) => handleKeyDownEscape(event, setOpen));
		})
	})
	return (
		<Box sx={actionBarStyle.box}>
			<Grid container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Grid item>
					<Button
						size={"small"} variant={"text"} sx={actionBarStyle.clear}
						onClick={() => setOpen(false)}
						onKeyDown={(event)=> handleKeyDownClose(event, setOpen)} 
						tabIndex={"0"} 
						role={"button"} 
					>
						Close
					</Button>
				</Grid>
				<Grid container item direction={"row"} justifyContent={"end"} spacing={1}>
					<Grid item>
						<Button
							size={"small"} variant={"text"} sx={actionBarStyle.clear}
							onClick={() => handleClear(setDateRange)}
							onKeyDown={(event)=> handleKeyDownClear(event, setDateRange)} 
							tabIndex={"0"} 
							role={"button"} 
						>
							Clear
						</Button>
					</Grid>
					<Grid item>
						<Button size={"small"} variant={"contained"} sx={actionBarStyle.accept}
						        onClick={() => {
							        handleApply(alert, setAlert, dateRange, setDateRange, storageKey)
									setEmpty(false)
									setHasError(false)
							        setOpen(false)
						        }}
						        disabled={!dateRange.selected[0] || !dateRange.selected[1]}
								onKeyDown={(event)=> handleKeyDownApply(event, setOpen, ()=> handleApply(alert, setAlert, dateRange, setDateRange, storageKey))} 
								tabIndex={"0"} 
								role={"button"} 
						>
							Apply
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
		;
}

