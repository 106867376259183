export const navDrawerStyle = (drawerOpen, drawerOpenStyle, drawerClosedStyle) => ({
	height: "100%",
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(drawerOpen ? drawerOpenStyle : drawerClosedStyle),
	"& .MuiDrawer-paper": {
		...(drawerOpen ? drawerOpenStyle : drawerClosedStyle),
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		border: "none",
		backgroundColor: "primaryColour.brand",
	},
})
