import React from "react";
import {getAggregationLevel, getFormattedDate} from "../../../../common/formatters";
import apiCall from "../../../../common/apiCall";
import {EmbedsCard} from "../Embed/EmbedsCard/EmbedsCard";
import {RenderVideoEmbeds} from "../Embed/RenderVideoEmbeds/RenderVideoEmbeds";
import {Videocam} from "@mui/icons-material";
import {defaultPalette} from "../../../../common/themes";

export const graphCardEmptyMessage = "Content overview currently unavailable - Select a different date range or check back soon"
export const emptyGraphMessage = "Graph data unavailable"

/**
 * Checks if video embeds has valid titles
 * @param {Object} mediaData An object holding information about video embeds.
 * @return {boolean} Whether video embeds has a title or not.
 */
const filterVideoTitle = (mediaData) => mediaData.mediaTitle != null

/**
 * Returns the title of the current video embed.
 * @param {Object} mediaData An object holding information about video embeds.
 * @return {string} The video embed title.
 */
const extractVideoTitleAndID = (mediaData) => ({title: mediaData.mediaTitle, id: mediaData.mediaId, active: mediaData.active})

/**
 * Remove whitespace from both ends of a string.
 * @param {string} type A string of the current social media type.
 * @return {string} Social media type with no whitespace on the word.
 */
export const trimString = (type) => type.trim()

/**
 * Groups social media embeds into an array.
 * @param {string} socialMediaData A string of all social media types of the content.
 * @return {Array} Array of all social media types of the content.
 */
export const parseSocialMediaTypes = (socialMediaData) => socialMediaData.split(",").map(trimString)

/**
 * Groups different embeds into an object.
 * @param {Object} dataInfo An object holding all information about the graph and content.
 * @return {Object} An object with relevant embeds as their keys.
 */
export const renderEmbedCards = (dataInfo) => {
	return {
		Video: dataInfo.content.embedVideo.length > 0 ?
			<EmbedsCard renderEmbed={<RenderVideoEmbeds embedData={dataInfo.content.embedVideo}/>}
			            icon={<Videocam/>} title={"Video Embeds"}/> : false,
	}
}

/**
 * Queries contentSummaryGraph and updates relevant states.
 * @param {Object} dateRange The start and end date of the current date picker.
 * @param {string} contentId The current content being searched.
 * @param {function} setDataInfo A function which updates the relevant state.
 * @return {void}
 */
export const contentSummaryGraphApi = (dateRange, contentId, setDataInfo, setHasError, setEmpty) => {

	setDataInfo((prevState) => ({
		...prevState,
		graph: {...prevState.graph, loading: true, error:false, empty:false}
	}))

	let startDate, endDate, aggregationLevel, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])
	aggregationLevel = getAggregationLevel(dateRange[0], dateRange[1]);

	query = `
                query contentSummaryGraph($startDate: String!, $endDate: String!, $contentId: String!, $aggregationLevel: String!) {
                    contentSummaryGraph(startDate: $startDate, endDate: $endDate, contentId: $contentId, aggregationLevel: $aggregationLevel) {
                        label1
                        click
                        label2
                        engage
                        dateTime
                    }
                }
          `;

	const variables = {
		startDate: startDate,
		endDate: endDate,
		contentId: contentId,
		aggregationLevel: aggregationLevel
	};

	apiCall(query, variables).then(response => {
		const {contentSummaryGraph: contentSummaryGraphData} = response;
		if (contentSummaryGraphData.length === 0) {
			throw new Error(`Content Graph query returned an empty array`)
		}

		let graphValues = contentSummaryGraphData.map(function (data) {
			data[data["label1"]] = data["click"]
			data[data["label2"]] = data["engage"]
			return data
		});
		graphValues = graphValues.map(({label1, label2, click, engage, ...data}) => data)

		// logic to determine whether date-range chosen returns graph data
		let label1Sum, label2Sum;
		label1Sum = graphValues.reduce((a, b) => +a + +b[contentSummaryGraphData[0]["label1"]], 0);
		label2Sum = graphValues.reduce((a, b) => +a + +b[contentSummaryGraphData[0]["label2"]], 0);

		// update data states
		setDataInfo((prevState) => ({
			...prevState,
			graph: {
				data: graphValues,
				labels: [contentSummaryGraphData[0]["label1"], contentSummaryGraphData[0]["label2"]],
				loading: false,
				error:false,
				empty: (label1Sum + label2Sum) <= 0,
			}
		}))
		if ((label1Sum + label2Sum) <= 0){
			setEmpty(true)
		}
	}).catch(error => {
		console.log({contentSummaryGraphError: error});
		setDataInfo((prevState) => ({
			...prevState,
			graph: {...prevState.graph, loading: false, error:true}
		}))
		setHasError(true)
	});
}

/**
 * Queries contentDetail and updates relevant states.
 * @param {Object} dateRange The start and end date of the current date picker.
 * @param {string} contentId The current content being searched.
 * @param {function} setDataInfo A function which updates the relevant state.
 * @return {void}
 */
export const contentDetailApi = (dateRange, contentId, setDataInfo, setHasError) => {

	setDataInfo((prevState) => ({
		...prevState,
		content: {...prevState.content, loading: true, error:false}
	}))

	let startDate, endDate, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])

	query = `
                query contentDetail($startDate: String!, $endDate: String!, $contentId: String!, ) {
                    contentDetail(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                        headline
                        publishTime
                        lastUpdate
                        edits
                        department
                        contentType
						contentSubType
                        commentFlag
                        videoCount
                        audioCount
                        embeddedSocialMediaCount
                        embeddedMedia {
                            embeddedVideo {
                            mediaId
                            mediaTitle
							active
                            }                   
                        }
                        embeddedSocialMediaType
                        categories
                        label
                        totalCount
                    }
                }
            `;

	const variables = {
		startDate: startDate,
		endDate: endDate,
		contentId: contentId
	};
	apiCall(query, variables).then(response => {
		const {contentDetail: contentDetailData} = response;
		if (contentDetailData.headline === null || Object.keys(contentDetailData).length === 0) {
			throw new Error(`Content Detail query returned an empty object`)
		}

		let departmentList = contentDetailData.department
		contentDetailData.contentSubType && departmentList.push(contentDetailData.contentSubType)

		let embedVideoTitles = contentDetailData.embeddedMedia.embeddedVideo === null ? [] :
			contentDetailData.embeddedMedia.embeddedVideo.filter(filterVideoTitle).map(extractVideoTitleAndID)

		let embedSocialMediaTypes = contentDetailData.embeddedSocialMediaType === null ? [] :
			parseSocialMediaTypes(contentDetailData.embeddedSocialMediaType)
		let
			embedData = [
				{title: "Video", num: contentDetailData.videoCount},
			]
		contentDetailData.commentFlag === "true" && embedData.push({title: "Comments", num: null})

		let palette = defaultPalette[contentDetailData.contentType] ? defaultPalette[contentDetailData.contentType] :
			defaultPalette["other"];

		// update data states
		setDataInfo((prevState) => ({
			...prevState,
			content: {
				data: contentDetailData,
				loading: false,
				error:false,
				overview: departmentList,
				embed: embedData,
				embedVideo: embedVideoTitles,
				embedSocialMedia: embedSocialMediaTypes,
				colourPalette: palette
			}
		}))
		
	}).catch(error => {
		console.log({contentDetailError: error});
		setDataInfo((prevState) => ({
			...prevState,
			content: {...prevState.content, loading: false, error:true}
		}))
		setHasError(true)
	});
}