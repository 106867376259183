export const dateInfoCardStyle = {
	paperOverride: {
		borderColor: "grey.400",
		borderWidth: "thin",
		width: "100%",
		height: "100%",
		margin: "auto",
		padding: 2
	},
	icon: {color: "grey.400"},
	text: {
		"& .MuiTypography-root": {typography: "text.body2"},
		paddingLeft: 2,
		paddingTop: 0.125
	}
}