import React from "react"
import { PaperGrid } from "../../../Foundational/PaperGrid/PaperGrid";
import {Box, Chip,  Grid, Typography} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { topNContentPDFStyle } from "./TopNContentPDFStyle";
import { topNContentPDFTableColumns } from "./TopNContentPDFHelper";
import PropTypes from "prop-types";

export const TopNContentPDF = ({startDate, endDate, department, platform, contentType, tableData}) => {
    const chips = [{title:"Department", body:department}, {title:"Timeframe", body: `${startDate} - ${endDate}`}, {title:"Platforms", body:platform}, {title:"Content Types", body:contentType}]
    return(
        <PaperGrid sxPaperOverride={topNContentPDFStyle.paperOverride}>
            <Grid container item direction={"column"} wrap={"nowrap"} spacing={1}>
                <Grid container item direction={"row"} wrap={"nowrap"} spacing={1}>
                    <Grid item>
                        <Box sx={topNContentPDFStyle.headerBox} />
                    </Grid>
                    <Grid item>
                        <Box sx={topNContentPDFStyle.horizontalSpacer}/>
                    </Grid>
                    <Grid container item direction={"column"} wrap={"nowrap"} spacing={1} marginTop={4} marginLeft={8} marginBottom={4}>
                        <Grid item>
                            <Box sx={topNContentPDFStyle.spacingBox}/>
                        </Grid>
                        <Grid item>
                            <Typography sx={topNContentPDFStyle.title}>
                                    Top Content Performers
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={topNContentPDFStyle.spacingBox}/>
                        </Grid>
                        <Grid container item direction={"row"} wrap={"nowrap"} justifyContent={"flex-start"}>
                            {chips.map((chip)=>{
                                return(
                                    <Grid container item direction={"row"} wrap={"nowrap"}>
                                        <Grid item>
                                            <Typography sx={topNContentPDFStyle.chipTitle}>
                                                {chip.title}:
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Chip
                                                label={chip.body}
                                                size={"medium"}
                                                variant={"default"}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid item>
                            <Box sx={topNContentPDFStyle.spacingBox}/>
                        </Grid>
                </Grid>
            </Grid>
            <Grid item align={"center"}>
					<DataGridPremium 
                        sx={topNContentPDFStyle.dataGrid}
                        columns={[...topNContentPDFTableColumns]}
                        rows={tableData}
                        columnHeaderHeight={38}
                        rowHeight={80}
                        autoHeight={true}
                    />
			</Grid>
            <Grid container item direction={"row"} justifyContent={"flex-start"} marginLeft={7}>
                <Grid item>
                    <Typography sx={topNContentPDFStyle.footerText}>
                        {"http://mir.cbc.ca/OCAD"}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </PaperGrid>
    )
}

TopNContentPDF.propTypes = {
	startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
	tableData: PropTypes.array.isRequired
}