import {gridDateComparator} from "@mui/x-data-grid-premium";

const valueFormatter = (params) => {
	let time, timeFormatted, options
	time = new Date(params.value)
	options = {year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};
	timeFormatted = time.toLocaleString("en-US", options);
	return `${timeFormatted}`;
}

export const headlineColumns = [
	{
		field: "timeOfChange",
		headerName: "Time of Change",
		flex: 1,
		editable: false,
		disableColumnMenu: true,
		valueFormatter: valueFormatter,
		sortComparator: gridDateComparator
	},
	{
		field: "headlines",
		headerName: "Headline",
		editable: false,
		disableColumnMenu: true,
		cellClassName: "headline-title",
		flex: 3
	},
]
