import {shortcutsItems} from "./CoreDateRangePickerHelper";
import React from "react";

export const CoreDateRangePickerStyle = {
	textField: {
		sx: {
			minWidth: 275,
			margin: 0,
			boxShadow: "none",
			input: { cursor: "pointer" } ,
			"& .MuiOutlinedInput-notchedOutline": {border: 0, padding:0},
			"& .MuiOutlinedInput-root": {
				"&.Mui-focused fieldset": {borderColor: "transparent", padding:0},
			},
		}
	},
	slotProps: {
		paper: {sx: {".MuiPaper-root": {borderRadius: "50px"}}},
		singleInputDateRangeField: {color: "error"},
		shortcuts: {
			items: shortcutsItems,
		},
		content: {
			sx: {
				"& .MuiDateRangeCalendar-monthContainer": {border: 0}
			},
			calendars: 1,
		},
		popper: {
			placement: "bottom-end"
		},
		desktopPaper: {
			sx: {
				borderRadius: "30px",
				border: 0,
				"& .MuiDateRangeCalendar-container": {borderRight: 0}
			}
		},
		day: {
			sx: {
				"& .MuiPickersDay-root.Mui-selected": {
					backgroundColor: "primaryColour.brand",
				},
			},
		},
		layout: {
			sx: {
				padding: 6,
			},
		}

	},
	helperText:{color:"common.black", fontWeight:600, cursor:"default"}
}