import {getFormattedDate} from "../../../../common/formatters";
import apiCall from "../../../../common/apiCall";

export const emptyMessage = "Before/After content data currently unavailable"
export const title = "What other content did audiences engage with before and after?"
export const tabMap = {0: "before", 1: "after"}
export const beforeAfterApi = (contentId, dateRange, setDataInfo, setHasError, setEmpty) => {
	setDataInfo((prevData) => ({...prevData, loading: true, error:false}))

	const startDate = getFormattedDate(dateRange[0])
	const endDate = getFormattedDate(dateRange[1])
	const query = `
            query contentBeforeAfter($startDate: String!, $endDate: String!, $contentId: String!) {
                contentBeforeAfter(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    before {
                        augmented {
                            lineups
                            categories {
                                category
                                count
                            }
                        }
                        data {
                            id
                            contentId
                            percentage
                            totalTimeSpent
                            avgTimeSpent
                            totalPageviewStreamed
                            totalReadWatched
                            readWatchedPercentage
                            contentHeadline
                            contentType
							contentSubtype
                            contentPublishTime
                            contentEdits
                            contentVideoCount
                            contentCategories
                        }
                    }
                    after {
                        augmented {
                            lineups
                            categories {
                                category
                                count
                            }
                        }
                        data {
                            id
                            contentId
                            percentage
                            totalTimeSpent
                            avgTimeSpent
                            totalPageviewStreamed
                            totalReadWatched
                            readWatchedPercentage
                            contentHeadline
                            contentType
							contentSubtype
                            contentPublishTime
                            contentEdits
                            contentVideoCount
                            contentCategories
                        }
                    }
                }
            }
        `;

	const queryVariable = {
		startDate: startDate,
		endDate: endDate,
		contentId: contentId
	};

	apiCall(query, queryVariable)
		.then(response => {
			const contentStage = ["before", "after"]
			const {contentBeforeAfter: beforeAfterData} = response;

			// Cleaning augmented lineup data - parsing into a string.
			const augmentedLineups = contentStage.map((stage) => {
				return beforeAfterData[stage].augmented.lineups.map((lineup) => {
					if (typeof lineup === "string" && lineup !== "-1" && lineup.length >= 2) {
						return lineup
					}
				})
			})
    
			// Cleaning augmented categories data - retrieving top 3 then parsing into a string.
			const augmentedCategories = contentStage.map((stage) => {
				return beforeAfterData[stage].augmented.categories.map((category) => {
					let value = category.category
					if (typeof value === "string" && value !== "-1" && value.length >= 2) {
						return value
					}
				})
			})

			setDataInfo({
				loading: false,
				error:false,
				data: {
					before: {
						table: beforeAfterData.before.data,
						augmentedAnalytics: {
							"lineup": augmentedLineups[0].join(", "),
							"categories": augmentedCategories[0].slice(0, 4).join(", ")
						}
					},
					after: {
						table: beforeAfterData.after.data,
						augmentedAnalytics: {
							"lineup": augmentedLineups[1].join(", "),
							"categories": augmentedCategories[1].slice(0, 4).join(", ")
						}
					}
				}
			})
            if (beforeAfterData.before.data.length == 0 || beforeAfterData.after.data.length == 0) {
                setEmpty(true)
            }
		}).catch(error => {
		console.log({contentBeforeAfterError: error});
		setDataInfo((prevData) => ({...prevData, loading: false, error:true}))
        setHasError(true)
	});
}

export const contentDetailApi = (contentId, dateRange, setContentDetailData) => {
    const startDate = getFormattedDate(dateRange[0])
	const endDate = getFormattedDate(dateRange[1])

	const query = `
                query contentDetail($startDate: String!, $endDate: String!, $contentId: String!, ) {
                    contentDetail(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                        headline
                        publishTime
                        lastUpdate
                    }
                }
            `;

	const variables = {
		startDate: startDate,
		endDate: endDate,
		contentId: contentId
	};

	apiCall(query, variables).then(response => {
		const {contentDetail: contentDetailData} = response;
		if (contentDetailData.headline === null || Object.keys(contentDetailData).length === 0) {
			throw new Error(`Content Detail query returned an empty object`)
		}

		// update data states
		setContentDetailData({
			...contentDetailData
		})
		
	}).catch(error => {
		console.log({contentDetailError: error});
		setContentDetailData({})
	});
}