import React from "react"
import { PaperGrid } from "../../../Foundational/PaperGrid/PaperGrid";
import {Box, Chip,  Grid, Typography} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { beforeAfterPDFTableColumns, dateOptions } from "./BeforeAfterPDFHelper";
import { beforeAfterPDFStyle } from "./BeforeAfterPDFStyle";
import PropTypes from "prop-types";

export const BeforeAfterPDF = ({contentId, startDate, endDate, augmentedAnalyticsData, tableData, contentDetailData}) => {
    const lastUpdate = contentDetailData?.lastUpdate? new Date(contentDetailData.lastUpdate).toLocaleString('en-US', dateOptions ) : "none"
    const publishTime = contentDetailData?.publishTime? new Date(contentDetailData.publishTime).toLocaleString('en-US', dateOptions ): "none"
    const chips = [{title:"Timeframe", body: `${startDate} - ${endDate}`}, {title:"Last Updated", body:lastUpdate}
    , {title:"Publication Time", body:publishTime}]
    return(
        <PaperGrid sxPaperOverride={beforeAfterPDFStyle.paperOverride}>
        <Grid container item direction={"column"} wrap={"nowrap"} spacing={1}>

            <Grid container item direction={"row"} wrap={"nowrap"} spacing={1}>
                <Grid item>
                    <Box sx={beforeAfterPDFStyle.headerBox} />
                </Grid>
                <Grid container item direction={"column"} wrap={"nowrap"} spacing={1} marginTop={4} marginLeft={4} marginBottom={2}>
                    <Grid item>
                        <Typography sx={beforeAfterPDFStyle.title}>
                                What other content did audiences engage with before and after:
                        </Typography>
                    </Grid>
                    {contentDetailData.headline &&
                    <Grid item>
                        <Typography sx={beforeAfterPDFStyle.header}>
                            {`${contentDetailData.headline} (${contentId})`}
                        </Typography>
                    </Grid>}
                    <Grid container item direction={"row"} wrap={"nowrap"} justifyContent={"flex-start"}>
                        {chips.map((chip)=>{
                            return(
                                <Grid container item direction={"row"} wrap={"nowrap"}>
                                    <Grid item>
                                        <Typography sx={beforeAfterPDFStyle.chipTitle}>
                                            {chip.title}:
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Chip
                                            label={chip.body}
                                            size={"medium"}
                                            variant={"default"}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={beforeAfterPDFStyle.spacingBox}/>
			<Grid container item direction={"column"} spacing={0.5} marginLeft={7}>
					{augmentedAnalyticsData.categories !== "" && <Grid item align={"left"}>
						<Typography sx={beforeAfterPDFStyle.text}>
							Audiences who engaged with this content are also interested in content
							about <Box sx={beforeAfterPDFStyle.highlight}
							           display={"inline"}>{augmentedAnalyticsData.categories}.</Box>
						</Typography>
					</Grid>}
					{augmentedAnalyticsData.lineup !== "" && <Grid item align={"left"}>
						<Typography sx={beforeAfterPDFStyle.text}>
							They highly frequented pages that include <Box sx={beforeAfterPDFStyle.highlight}
							                                               display={"inline"}>{augmentedAnalyticsData.lineup}.</Box>
						</Typography>
					</Grid>}
			</Grid>
            <Box sx={{
                paddingY:1.5
            }}/>
            <Grid item align={"center"} marginLeft={7}>
					<DataGridPremium 
                            sx={beforeAfterPDFStyle.dataGrid}
                        columns={[...beforeAfterPDFTableColumns]}
                        rows={tableData}
                        columnHeaderHeight={38}
                        rowHeight={80}
                        autoHeight={true}
                    />
			</Grid>
            <Grid container item direction={"row"} justifyContent={"flex-start"} marginLeft={7}>
                <Grid item>
                    <Typography sx={beforeAfterPDFStyle.footerText}>
                        {`http://mir.cbc.ca/DCAD/${contentId}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </PaperGrid>
    )
}

BeforeAfterPDF.propTypes = {
	startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired,
    contentDetailData: PropTypes.shape({
		lastUpdate: PropTypes.string,
        publishTime: PropTypes.string,
		headline: PropTypes.string.isRequired,
	}).isRequired,
    tableData: PropTypes.array.isRequired,
    augmentedAnalyticsData: PropTypes.shape({
		lineup: PropTypes.string,
        categories: PropTypes.string,
	}).isRequired,
}