import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {InfoOutlined as InfoIconOutlined} from "@mui/icons-material";
import {dateInfoCardStyle} from "./DateInfoCardStyle";
import {PaperGrid} from "../../../Foundational/PaperGrid/PaperGrid";

export const DateInfoCard = () => {
	return (
		<PaperGrid variant={"outlined"}
		           sxPaperOverride={dateInfoCardStyle.paperOverride}>
			<Grid container item wrap={"nowrap"}>
				<Grid item>
					<InfoIconOutlined sx={dateInfoCardStyle.icon}/>
				</Grid>
				<Grid item justifyContent={"center"} sx={dateInfoCardStyle.text}>
					<Typography textAlign={"left"}>
						<Box fontWeight={700} display='inline'>
							Select a date range starting on or after a content’s posted date.
						</Box> Minimum search period is 1 day. Maximum is 365 days.
					</Typography>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}