import React from 'react'
import {Container, Grid, LinearProgress, Typography} from "@mui/material";
import defaultIllustration from "../../../../../images/search-illustration.svg"
import {illustrationProgressStyle} from "./IllustrationProgressStyle";

export const IllustrationProgress = ({copyMessage, illustration = defaultIllustration, overlay = false}) => {
	const defaultCopy = "Retrieving your content. Hang tight!";
	return (
		<Container sx={
			overlay ? illustrationProgressStyle.overlay : illustrationProgressStyle.contained
		}>
			<Grid container direction={"column"} sx={illustrationProgressStyle.grid} justifyContent={"space-between"}>
				<Grid item>
					<LinearProgress sx={illustrationProgressStyle.linearProgress}/>
				< /Grid>
				<Grid container item direction={"column"} justifyContent={"center"} alignContent={"center"}>
					<Grid item>
						<img src={illustration} height={330} width={350}/>
					</Grid>
					<Grid item>
						<Typography textAlign={"center"} sx={illustrationProgressStyle.text}>
							{copyMessage ? copyMessage : defaultCopy}
						</Typography>
					</Grid>
				</Grid>
				<Grid item>
				</Grid>
			</Grid>
		</Container>
	)
}