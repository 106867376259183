import React from 'react'
import {Grid, Skeleton} from "@mui/material";

export const SmallTableSkeleton = () => {
	return (
		<Grid container item direction={"column"} spacing={2}>
			<Grid item>
				<Skeleton width={"50%"}/>
			</Grid>
			<Grid item>
				<Skeleton variant={"rectangular"} height={200}/>
			</Grid>
		</Grid>
	)
}