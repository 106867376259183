import React from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import GlobalStyles from "./retired/GlobalStyles";
import routes from "./routes";
import {AuthContextProvider} from "./firebase/context";
import {PrivateRoute} from "./PrivateRoute";
import {TitleManager} from "./TitleManager";

export const loadingTypes = {
    view: "view",
    data: "data"
};

export const App = () => {

    return (
        <AuthContextProvider>
            <GlobalStyles/>
            <Router>
                <TitleManager/>
                <Switch>
                    {routes.map(({path, exact, component}, index) => (
                        <PrivateRoute key={`route${index + 1}`} path={path} exact={exact}>
                            {React.cloneElement(component)}
                        </PrivateRoute>
                    ))}
                </Switch>
            </Router>
        </AuthContextProvider>
    );
};

