import ReactDOM from "react-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

/**
 * Exports a React component as a PDF file.
 *
 * @param {string} pdfTitle - The title of the PDF file to be saved.
 * @param {ReactElement} pdf - The React component to render and export as PDF.
 */
export const exportPDF = (pdfTitle, pdf) => {
    const domNode = document.createElement("div");
    domNode.style.position = "absolute";
    domNode.style.left = "-9999px"; 
    domNode.style.width = "3000px"; 
    document.body.appendChild(domNode);
    ReactDOM.render(pdf, domNode);

    setTimeout(() => {
        html2canvas(domNode, {
            scale: 2,
            width: domNode.scrollWidth, 
            height: domNode.scrollHeight, 
        }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", "a4");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save(pdfTitle)

        ReactDOM.unmountComponentAtNode(domNode); 
        document.body.removeChild(domNode); 
    });
    }, 1000);
}