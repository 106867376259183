import React from 'react';
import {PaperGrid} from "../../../Foundational/PaperGrid/PaperGrid";
import {Box, Grid} from "@mui/material";
import {InfoOutlined as InfoIconOutlined} from "@mui/icons-material";
import {versionInfoCardStyle} from "./VersionInfoCardStyle";

export function VersionInfoCard({}) {

	return (
		<PaperGrid variant={"outlined"}
		           sxPaperOverride={versionInfoCardStyle.paperOverride}>
			<Grid container item spacing={3} wrap={"nowrap"}>
				<Grid item>
					<InfoIconOutlined sx={versionInfoCardStyle.icon}/>
				</Grid>
				<Grid item sx={versionInfoCardStyle.text}>
					<Box textAlign={"left"}>
						This product is in <Box fontWeight={700} display="inline">development</Box>.
						The majority of content data is currently available 2 hours after the time of original
						posting. Have any thoughts you’d like to share? Give Feedback.
					</Box>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}