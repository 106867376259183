import {isWithinRange} from "../../../../common/utils"

export const handleApply = (alert, setAlert, dateRange, setDateRange, storageKey) => {
	const selectedStartDate = dateRange.selected[0]
	const selectedEndDate = dateRange.selected[1]

	if (selectedStartDate && selectedEndDate) {
		if (isWithinRange(selectedStartDate, selectedEndDate)) {
			setAlert({...alert, active: false})
			setDateRange((prevDateRange) => ({
				...prevDateRange,
				validated: [selectedStartDate, selectedEndDate],
			}))
			sessionStorage.removeItem("referrer")
			sessionStorage.setItem(storageKey, JSON.stringify([selectedStartDate, selectedEndDate]))
		} else {
			setAlert({...alert, active: true})
		}
	}
}

export const handleClear = (setDateRange) => {
	setDateRange((prevDateRange) => ({
		...prevDateRange,
		selected: [null, null],
	}))
}

export const handleKeyDownEscape = (event, setOpen) => {
	if(event.key == "Escape" ) setOpen(false)
}

export const handleKeyDownClose = (event, setOpen) => {
	if (event.key === "Enter") setOpen(false)
}

export const handleKeyDownClear = (event, setDateRange) => {
	if (event.key === "Enter") handleClear(setDateRange)
}

export const handleKeyDownApply = (event, setOpen, apply) => {
	if (event.key === "Enter"){
		apply()
		setOpen(false)
	}
}