import {RenderChips} from "../RenderCell/RenderChips/RenderChips";
import {RenderContentDetail} from "../RenderCell/RenderContentDetail/RenderContentDetail";
import {csvTimeFormatter, getContent, timeFormatter, valueFormatter} from "../TablesHelper";
import {gridStringOrNumberComparator} from "@mui/x-data-grid-premium";
import {RenderCommentChip} from "../RenderCell/RenderCommentChip/RenderCommentChip";
import {RenderVideoEmbedChip} from "../RenderCell/RenderVideoEmbedChip/RenderVideoEmbedChip";
import { RenderDCADNavigation } from "../RenderCell/RenderDCADNavigation/RenderDCADNavigation";
import { RenderTimeFormatter } from "../RenderCell/RenderTimeFormatter/RenderTimeFormatter";

const categoriesColumnFormatter = (params) => {
	let categories = params.value.filter(
		(label) => {
			return label !== "-1"
		}
	)
	return categories.join(", ")
}

const embeddedMediaFormatter = ({value}) =>{
	const videos = value?.embeddedVideo
	if (videos?.length){
		const videoUrls = videos.map(video => `https://www.cbc.ca/player/play/${video.mediaId}`);
		return videoUrls.length > 1 ? videoUrls.join(", ") : videoUrls[0]
	}
	return "No video"
}

const readWatchedColumnFormatter = (params) => (`${params.value}`)

const accessor = {
	timestamp: "publishTime", title: "headline", type: "contentType",
	contentId: "contentId", referrer:"OCAD"
}

export const topNColumns = [
	{
		field: "headline",
		headerName: "Content",
		flex: 3,
		valueGetter: getContent.bind(accessor),
		renderCell: RenderContentDetail,
		editable: false,
		disableColumnMenu: true,
		valueFormatter: ({value}) => (value.title + " - " + value.contentId),
		sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1.title, v2.title, param1, param2),
	},
	{
		field: "embeddedMedia",
		headerName: "Video",
		flex: 0.75,
		renderCell: RenderVideoEmbedChip,
		valueFormatter:embeddedMediaFormatter,
		editable: false,
		disableColumnMenu: true,
	},
	{
		field: "commentFlag",
		headerName: "Comment",
		flex: 1,
		renderCell: RenderCommentChip,
		editable: false,
		disableColumnMenu: true,
	},
	{
		field: "totalPageviewStreamed",
		headerName: "Pageviews, Streams",
		flex: 1,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
		editable: false,
		disableColumnMenu: true,
	},

	{
		field: "avgTimeSpent",
		headerName: "Average Time Spent",
		flex: 1,
		renderCell: RenderTimeFormatter,
		valueFormatter:csvTimeFormatter,
		editable: false,
		disableColumnMenu: true,
	},

	{
		field: "totalTimeSpent",
		headerName: "Total Time Spent",
		flex: 1,
		renderCell: RenderTimeFormatter,
		valueFormatter:csvTimeFormatter,
		editable: false,
		disableColumnMenu: true,
	},

	{
		field: "readWatchedPercentage",
		headerName: "%Read, %Watch",
		flex: 1,
		valueFormatter: readWatchedColumnFormatter,
		editable: false,
		disableColumnMenu: true,
	},

	{
		field: "totalReadWatched",
		headerName: "Total Read, Total Watch",
		flex: 1,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
		editable: false,
		disableColumnMenu: true,
	},

	{
		field: "categories",
		headerName: "Categories",
		flex: 1,
		renderCell: RenderChips,
		valueFormatter: categoriesColumnFormatter,
		cellClassName: "numbers",
		editable: false,
		disableColumnMenu: true,
		sortable: false
	},
]