import React from "react"
import { Button} from "@mui/material";
import PollIcon from "@mui/icons-material/Poll";
import { renderDCADNavigationStyle } from "./RenderDCADNavigationStyle";

export const RenderDCADNavigation = (params) => {
    const content = params.value
    return(
    <Button sx={renderDCADNavigationStyle.button} startIcon={<PollIcon />} 
    onClick={() => {
        sessionStorage.setItem("referrer", JSON.stringify(content.dateRange))
        window.open(`/DCAD/${content.contentId}`, "_blank").focus()
        }}> 
        Insights
    </Button>
)}