export const calculateNextTriggerTime = () => {
    const now = new Date();
    const minutes = now.getMinutes();

    const triggerMinutes = [1, 16, 31, 46];  // Specific minutes for notification

    let nextTriggerMinutes = triggerMinutes.find(minute => minute > minutes);

    if (nextTriggerMinutes === undefined) {
        nextTriggerMinutes = triggerMinutes[0];
        now.setHours(now.getHours() + 1);
    }

    const nextTrigger = new Date(now);
    nextTrigger.setMinutes(nextTriggerMinutes);
    nextTrigger.setSeconds(0);

    return nextTrigger.getTime() - now.getTime();
};

export const handleRefresh = () => {
    window.location.reload();
};
