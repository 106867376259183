import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import { renderContentTitleStyle } from "./RenderContentTitleStyle";

const ContentTitleCell = React.memo(({content}) => {
	return (
		<Box sx={renderContentTitleStyle.contentContainer}>
			<Grid container item direction={"row"} wrap={"nowrap"}>
				<Grid item container direction={"column"} alignItems={"flex-start"} justifyContent={"space-between"}>
					<Grid item>
						<Typography sx={renderContentTitleStyle.title}>
							{content.title}
						</Typography>
					</Grid>
					<Grid container item direciton={"row"} alignItems={"flex-start"} justifyContent={"start"}>
						{content.timestamp &&
						<Grid item>
							<Typography sx={renderContentTitleStyle.subTitle}>
								Published: {content.timestamp + " ET"}
							</Typography>
						</Grid>}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
})

export const RenderContentTitle = (params) => (<ContentTitleCell content={params.value}/>)