export const tagCardStyle = {
	titleChip: {
		color: "grey.800",
		backgroundColor: "white"
	},
	titleChipLabel: {
		typography: "text.body1"
	},
	titleChipIcon: {
		color: "grey.800"
	},
	grid: {
		padding: (0, 0, 0.75, 0.75)
	},
	contentChip: {
		backgroundColor: "grey.100",
		typography: "text.chipSmall",
		fontWeight: "600"
	},
	info: {
		backgroundColor: "white",
		color: "white"
	},
	regularCardWidth: {
		padding: 2,
		height: "100%",
		width: "90%",
	},
	shortendCardWidth: {
		padding: 2,
		height: "100%",
		width: "45%"
	}
}