import apiCall from "../../../../../common/apiCall";
import {getAggregationLevel, getFormattedDate} from "../../../../../common/formatters";
import {getDepartments} from "../../../../../common/utils";

export const emptyMessage = "KPI Metrics currently unavailable"

export const kpis = [
	{
		title: "Total Sessions",
		accessor: "totalSessions",
		duration: 1,
		units: null,
	},
	{
		title: "Total Pageviews",
		accessor: "totalPageviews",
		duration: 1.5,
		units: null,
	},
	{
		title: "Total Streams",
		accessor: "totalStreams",
		duration: 2,
		units: null,
	},
	{
		title: "Average Time Spent",
		accessor: "avgTimeSpent",
		duration: 2.5,
		units: "s",
	}
];

export const keyMetricsApi = (dateRange, department, departments, setDataInfo, setEmpty, setHasError) => {
	setDataInfo((prevState) => ({...prevState, loading: true, error: false}))

	let startDate, endDate, aggregationLevel, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])
	aggregationLevel = getAggregationLevel(dateRange[0], dateRange[1]);

	query = `
			query keyMetrics($startDate: String!, $endDate: String!, $aggregationLevel: String!, $department: [String!]!) {
				keyMetrics(startDate: $startDate, endDate: $endDate, aggregationLevel: $aggregationLevel, department: $department) {
					kpi {
						totalSessions
						totalPageviews
						totalStreams
						avgTimeSpent
					}
				}
			}
		`;

	const queryVariable = {
		startDate: startDate,
		endDate: endDate,
		aggregationLevel: aggregationLevel,
		department: getDepartments(department, departments)
	};

	apiCall(query, queryVariable)
		.then(response => {
			const {keyMetrics: {kpi: data}} = response;
			if (Object.keys(data).length === 0) {
				setDataInfo({data: kpis.map((kpi)=>{
					return{'${kpi.accessor}':0}
				}), loading: false, error:false})
				setEmpty(true)
			} else {
				setDataInfo({data: data, loading: false, error:false})
			}
		})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: true, error:true}))
			setHasError(true)
			console.log({kpiError: error});
		});
}