export const DateRangeShortcutsStyle = {
	list: {
		display: "flex",
		px: 4,
		"& .MuiListItem-root": {
			pt: 0,
			pl: 0,
			pr: 1,
		},
	},
	box: {
		gridRow: 1,
		gridColumn: 2,
	},
	chip: {
		backgroundColor: "primaryColour.chip",
		color: "common.white",
		"&:hover": {
			backgroundColor: "primaryColour.brand",
		},
	}
}