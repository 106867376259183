import React, {useContext, useEffect, useState} from "react";
import {firebaseAuth} from "./firebase";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {ID_TOKEN_STORAGE} from "../common/variables";
import {verifyUserTable} from "../common/verifyUserTable";

export const AuthContext = React.createContext(null)
export const AuthContextProvider = ({children}) => {

	const [currentUser, setCurrentUser] = useState(null)
	const [loading, setLoading] = useState(false)
	const [isAuth, setIsAuth] = useState(null)

	const signOut = () => {
		localStorage.removeItem(ID_TOKEN_STORAGE);
		setIsAuth(null)
		return firebaseAuth.signOut()
	}

	const signInWithGoogle = () => {
		const googleProvider = new GoogleAuthProvider()

		signInWithPopup(firebaseAuth, googleProvider)
			.then((result) => {
				setLoading(true)
				firebaseAuth.currentUser.getIdToken(true).then((idToken) => {
					localStorage.setItem(ID_TOKEN_STORAGE, idToken)

					verifyUserTable().then(userObj => {
						const isApproved = userObj.isApproved
						if (!isApproved) {
							setIsAuth(false)
							setLoading(false)
							console.error("Current user is not approved in the user table.")
							return signOut()
						}
						setIsAuth(true)
						setLoading(false)
					})
				});

			}).catch((error) => {
			setLoading(false)
			if (error.code !== "auth/cancelled-popup-request" && error.code !== "auth/popup-closed-by-user") {
				console.error(error)
			}
		});
	};


	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
			setCurrentUser(user)

			if (user) {
				user.getIdTokenResult().then((idTokenResult) => {
					if (new Date(idTokenResult.expirationTime).getTime() < Date.now()) {
						signOut().then(() => {
							window.location.reload();
						});
					}
				}).catch((error) => {
					console.error("Error getting token info:", error)
				})
			}
		})

		return unsubscribe
	}, [])

	const value = {currentUser, loading, isAuth, signInWithGoogle, signOut}

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext)
