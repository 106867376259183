import {getFormattedDate} from "../../../../common/formatters";
import apiCall from "../../../../common/apiCall";


export const headlineApi = (dateRange, contentId, setDataInfo, setHasError, setEmpty) => {

	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	let startDate, endDate, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])

	query = `
            query headlineChanges($startDate: String!, $endDate: String!, $contentId: String!) {
                headlineChanges(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    metadata {
                        contentId
                        startDate
                        endDate
                        metrics
                    }
                    data {
                      id
                      timeOfChange
                      headlines
                    }
                }
            }
      `;

	const variables = {startDate: startDate, endDate: endDate, contentId: contentId};

	apiCall(query, variables)
		.then(response => {
			const {headlineChanges: headlineChangesData} = response;

			setDataInfo({
				data: headlineChangesData.data,
				loading: false,
				error:false
			})
			if (headlineChangesData.data.length == 0){
				setEmpty(true)
			}
		})
		.catch(error => {
			console.log({headlineChangesError: error});
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
		});
}