import React from "react";
import {Box, Divider, Grid} from "@mui/material";
import {grouperStyle} from "../Grouper/GrouperStyle"
import PropTypes from "prop-types";

export const Grouper = ({items}) => {
	return (
		<Box>
			<Grid container direction={"row"} wrap={"nowrap"}
			      sx={grouperStyle.grid}
			>
				{items.map((item, index) => {
					return (
						<>
							<Grid item sx={{
								paddingLeft: index >= 1 ? 2: 0,
								paddingRight: index <= items.length - 1 ? 2: 0,
							}}>
								{item}
							</Grid>
							<Divider orientation={"vertical"} flexItem={true} variant={"middle"}
							         sx={grouperStyle.divider(items, index)}/>
						</>
					)
				})}
			</Grid>
		</Box>
	);
}

Grouper.propTypes = {
	items: PropTypes.array.isRequired,
}