import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import hoverLogo from "../../../../../images/logo-black.svg";
import mainLogo from "../../../../../images/logo-white.svg";
import {defaultDrawerListItemButtonStyle} from "../../../../../common/styles";
import React from "react";

export function LogoDrawerListItemButton({item, state, action}) {
	return (
		<ListItemButton
			component={RouterLink}
			to={item.path}
			onMouseEnter={() => {
				action(hoverLogo)
			}}
			onMouseLeave={() => {
				action(mainLogo)
			}}
			onFocus={() => action(hoverLogo)}
			onBlur={() =>action(mainLogo)}
			sx={(theme) => ({
				...defaultDrawerListItemButtonStyle,
				paddingLeft: '10px',
			})}
		>
			<ListItemIcon>
				<img src={state} width="36" height="36"/>
			</ListItemIcon>
			<ListItemText sx={{
				'& .MuiListItemText-primary': (theme) => (theme.typography.text.h5)
			}}>
				{item.name}
			</ListItemText>
		</ListItemButton>)
}