export const versionInfoCardStyle = {
	paperOverride: {
		borderColor: "grey.400",
		borderWidth: "thin",
		width: "100%",
		height: "100%",
		margin: "auto",
		padding: 2
	},
	icon: {color: "grey.400"},
	text: {'& .MuiBox-root': {typography: "text.body2"}}
}