export const actionBarStyle = {
	accept: {
		backgroundColor: "primaryColour.brand",
		color: "common.white",
		"&:hover": {
			backgroundColor: "primaryColour.brand",
		},
	},
	clear: {
		color: "secondaryColour.main",
	},
	box: {
		gridRow: 3,
		gridColumn: 2,
	}
}