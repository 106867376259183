import React from 'react'
import {Grid, Skeleton} from "@mui/material";

export const KPISkeleton = ({num}) => {
	const rectangles = Array(num).fill(0)
	return (
		<Grid container item direction={"row"} spacing={2} justifyContent={"space-between"}>
			{rectangles.map((_) => {
				return (
					<Grid item xs={12.0 / num}>
						<Skeleton variant={"rectangular"} width={"100%"} height={100}/>
					</Grid>
				)
			})}
		</Grid>
	)
}