import apiCall from "../../../../common/apiCall";
import {getFormattedDate} from "../../../../common/formatters";
import {getDepartments} from "../../../../common/utils";

export const platformTitle = "Platforms"
export const platforms = [{id: "All Platforms", name: "All Platforms"}, {id: "web", name: "Web"}, {
	id: "apps",
	name: "Apps"
}, {id: "ott", name: "Gem"}]
export const mediaTitle = "Content Types"
export const media = [{id: "All Media", name: "All Content Types"}, {id: "Live Video", name: "Live Video"},
	{id: "On-Demand Video", name: "On-Demand Video"}, {id: "Standard Article", name: "Standard Article"}]
export const defaultSectionValue = "All Sections"
export const emptyMessage = "Top performer data currently unavailable"
export const title = "Top Content Performers"

export const topNApi = (dateRange, department, departments, platformFilter, mediaFilter, setDataInfo, setEmpty, setHasError) => {
	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	let startDate, endDate, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])

	query = `
			query topNContent($startDate: String!, $endDate: String!, $platform: String!, $media: String!, $section1: String!,$section2: String!,$section3: String!,$section4: String!, $department: [String!]!) {
                    topNContent(startDate: $startDate, endDate: $endDate, platform: $platform, media: $media, section1: $section1, section2: $section2, section3: $section3, section4: $section4, department: $department) {
                        id
                        headline
                        publishTime
                        contentId
                        categories
                        embeddedMedia {
                            embeddedVideo {
                            mediaId
                            mediaTitle
							active
                            }                   
                        }
                        contentType
						contentSubtype
                        commentFlag
                        totalPageviewStreamed
                        totalReadWatched
                        readWatchedPercentage
                        avgTimeSpent
                        totalTimeSpent
                    }
                }
            `;

	const variables = {
		startDate: startDate,
		endDate: endDate,
		department: getDepartments(department, departments),
		platform: platformFilter.id,
		media: mediaFilter.id,
		section1: defaultSectionValue,
		section2: defaultSectionValue,
		section3: defaultSectionValue,
		section4: defaultSectionValue,
	};

	apiCall(query, variables).then(response => {
		let data = response['topNContent']
		setDataInfo({
			data: data,
			loading: false,
			error:false
		})

		if (data.length == 0){
			setEmpty(true)
		}
	})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
			console.log({topNContentError: error});
		});


}