import {Box, Grid} from "@mui/material";
import React from "react";
import {renderVideoEmbedsStyle} from "./RenderVideoEmbedsStyle";

export function RenderVideoEmbeds({embedData}) {
	return (embedData.map(function renderEmbed(embed, index) {
		const url = `https://www.cbc.ca/player/play/${embed.id}`
		return (
			<Grid container item direction={"row"} key={index + embed} wrap={"nowrap"}>
				<Grid item sx={embed.active ? {...renderVideoEmbedsStyle.embed, textDecoration: 'underline', ": hover": {cursor: "pointer"}} : renderVideoEmbedsStyle.embed} 
				onClick={embed.active? () => {
					window.open(url, '_blank').focus();
				}:()=>{}}>
					<Box>
						{embed.title}
					</Box>
				</Grid>
			</Grid>
		)
	}))
}
