import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {H1, H3, Small} from "../../../../retired/components/Elements/Elements";
import {SITE_DESC, SITE_TITLE} from "../../../../common/variables";
import {useAuth} from "../../../../firebase/context";
import GoogleButton from "react-google-button";
import { handleKeyDown } from "./LoginHelper";

export const Login = () => {

	useEffect(() => {
		document.title = "Login";
	}, []);

    const [message, setMessage] = useState(null);
    const { signInWithGoogle } = useAuth();

    const handleGoogleSignIn = async() => {
        try {
            await signInWithGoogle();
        } catch (error) {
            console.error(error)
            setMessage("Something went wrong during your authentication process.");
        }
    }

    return (
        <Content>
            <Heading>{SITE_TITLE}</Heading>
            <SubHeading>{SITE_DESC.toLowerCase()}</SubHeading>
            {message && (
                <Message>
                    <span>{message}</span>
                </Message>
            )}
            <Copy>Sign in using your CBC&nbsp;account</Copy>
            <GoogleButton 
                style={{margin: "auto"}} 
                onClick={handleGoogleSignIn}
                onKeyDown={(event) => handleKeyDown(event, handleGoogleSignIn)}
                tabIndex={"0"} 
                role={"button"} 
            />
        </Content>
    );
};

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 30px;
  width: 100%;
  text-align: center;
`;

const Heading = styled(H1)`
  margin: 0;
  padding: 0;
`;

const SubHeading = styled(H3)`
  margin: 0 0 50px 0;
  padding: 0;
`;

const Copy = styled(Small)`
  margin: 0 0 20px 0;
  padding: 0;
`;

const Message = styled.p`
  margin: 40px 0 120px 0;
  padding: 0;

  span {
    padding: 10px 20px;
    background-color: #000000;
    color: #ffffff;
    font-size: 12pt;
  }
`;
