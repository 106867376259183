export const beforeAfterTableStyle = {
	dataGrid: {
		'& .MuiDataGrid-columnHeaderTitle': {typography: "text.body1"},
		'& .MuiDataGrid-cell': {typography: "text.body2"},
		'& .numbers': {typography: "numeric.body2"},
		'& .MuiButton-root': {
			typography: "text.body1",
			color: "common.black"
		},
		'& .MuiDataGrid-cell:hover': {
			cursor: "pointer",
		},
		'& .MuiDataGrid-overlayWrapper': {
			position: 'sticky',
			top: 0,
			left: 0,
			width: '100%',
			zIndex: 4,
		  },

		maxWidth: "1022px"
	},
	toolbarContainer: {
		justifyContent: "flex-end"
	},
}