export const amountFormatter = (amount) => {
    if (amount === null || amount === undefined) {
        return '0';
    }
    return Number(amount.toFixed(2)).toLocaleString();
}

export const secondsToTime = (seconds) => {
	seconds = Number(seconds);
	let h = Math.floor(seconds / 3600);
	let m = Math.floor(seconds % 3600 / 60);
	let s = Math.floor(seconds % 60);

	let hDisplay = h > 0 ? (amountFormatter(h) + "h ") : "";
	let mDisplay = m > 0 ? (m + "m ") : "";
	let sDisplay = s > 0 ? (s + "s") : "00s"; // change this

	return hDisplay + mDisplay + sDisplay;
}

export const getFormattedDate = (date) => {
	let year = date.getFullYear();
	let month = (1 + date.getMonth()).toString().padStart(2, "0");
	let day = date.getDate().toString().padStart(2, "0");

	return year + "-" + month + "-" + day;
}

export const getFormattedDateString = (date) => {
	let dateObject, options
	dateObject = new Date(date)
	options = {year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};

	return dateObject.toLocaleString("en-US", options)
}

export const getShortFormattedDateString = (date) => {
	let dateObject, options
	dateObject = new Date(date)
	options = {year: 'numeric', month: 'long', day: 'numeric'};

	return dateObject.toLocaleString('en-US', options)
}

export const aggregationLevel = {
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY"
};

export const dayDifference = (startDate, endDate) => {

    const normalizedStartDate = new Date(startDate);
    const normalizedEndDate = new Date(endDate);

    normalizedStartDate.setHours(12, 0, 0, 0);
    normalizedEndDate.setHours(12, 0, 0, 0);

    let timeDiff = Math.abs(normalizedStartDate - normalizedEndDate);
    let dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return dayDiff;
}

export const getAggregationLevel = (startDate, endDate) => {
	const dayDiff = dayDifference(startDate, endDate)
	return dayDiff < 1 ? aggregationLevel.HOURLY : dayDiff <= 31 ? aggregationLevel.DAILY : aggregationLevel.WEEKLY;
}

export const formatterHelp = (type, value) => {

	switch (type) {
		case "duration":
			return secondsToTime(value);

		case "amount":
		default:
			return amountFormatter(value);
	}
}