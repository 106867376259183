import { emptyStateSVGPalette } from "../../../../common/themes"
export const CustomNoRowsOverlayStyle = {
    box:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        ".ant-empty-img-1": {
          fill: emptyStateSVGPalette.img1,
        },
        ".ant-empty-img-2": {
          fill: emptyStateSVGPalette.img2,
        },
        ".ant-empty-img-3": {
          fill: emptyStateSVGPalette.img3,
        },
        ".ant-empty-img-4": {
          fill: "white",
        },
        ".ant-empty-img-5": {
          fillOpacity: emptyStateSVGPalette.img5.opacity,
          fill: emptyStateSVGPalette.img5.color,
        }
    },
      text:{ typography: "text.body2"},
      svg:{ flexShrink: 0 }
}