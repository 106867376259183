export const dropdownStyle = {
	filter: {
		'& .MuiInputBase-root': {typography: "text.body2"},
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {borderColor: "primaryColour.brand"},
		},
		'& .MuiInput-underline:after': {borderColor: "primaryColour.brand"},
		'& .MuiFilledInput-underline:after': {borderColor: "primaryColour.brand"},
		'& .MuiInputLabel-root': {
			'&.Mui-focused': {color: "primaryColour.brand"},
		},
		minWidth: 200,
		margin: 0
	},
	menuItem: {
		'& .MuiMenuItem-root': {typography: "text.body2"}
	}
}