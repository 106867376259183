export const renderVideoEmbedsStyle = {
	embed: {
		"& .MuiBox-root": (theme) => (theme.typography.text.chipSmall),
		color: "common.black",
	},
	button: {
		color: "common.black",
		paddingLeft: 0,
		paddingY: 0,
	}
}