import {makeStyles} from "@mui/styles";

export const logoProgressStyle = {
    background: "common.black",
    overlay: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "grey.50",
        display: "flex",
    },
    fill: {
        width: "100%",
        height: "100%",
        backgroundColor: "grey.50",
        display: "flex",

    },
}

export const useStyles = makeStyles((theme) => ({
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }

    },
    "@keyframes ping": {
        "0%": {
            transform: "scale(0.5)",
            opacity: 0.9
        },
        '80%': {
            transform: "scale(1.5)",
            opacity: 0.0
        },
        '100%': {
            transform: "scale(2.5)",
            opacity: 0
        }
    },
    rotateAnimation: {
        animation: "$rotate 1.4s linear infinite"
    },
    pingAnimation: {
        animation: "$ping 2s ease-in-out infinite both"
    }
}))