export const stateCardStyle = {
	icon: {
		color: "grey.700"
	},

	text: {
		color: "grey.700",
		"& .MuiTypography-root": {...(theme) => (theme.typography.text.body2), paddingTop:0.1},
	},

	linearProgress: {
		height: 10,
		borderRadius: 5,
		backgroundColor: "grey.50",
		"& .MuiLinearProgress-bar": {backgroundColor: "primaryColour.brand"},
	}
}
