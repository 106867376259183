export const subjectRangeCardStyle = {
    text: {
        typography: "text.body2", opacity:0.6
    },
    zoneName : {typography:"text.chipSmall", color:"white"},
    zoneBoxTop: {backgroundColor: "primaryColour.brand", borderRadius:"4px", px:1},
    zoneBoxBottom:{backgroundColor: "secondaryColour.main", borderRadius:"4px", px:1},
    title:{typography: "text.body1", fontWeight:700},
    value:{typography:"numeric.h5"},
    separator:{typography:"numeric.h5"},
    sxPaperOverride: {
        padding:2
    }

}