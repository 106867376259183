import {Chip} from "@mui/material";
import React from "react";
import {RenderCommentChipStyle} from "./RenderCommentChipStyle";

export const RenderCommentChip = (params) => {
	if (params?.value === "true") {
		return <Chip
			label={"Comment"}
			size={"small"}
			variant={"outlined"}
			sx={RenderCommentChipStyle.embed}
		/>
	}
	return <></>
}