import React from "react";
import { Paper, Stack, Typography, Grid } from "@mui/material";
import { detailPanelContentStyle } from "./DetailPanelContentStyle";
import { TagTopNTable } from "../../../TagTopNTable/TagTopNTable";
import PropTypes from "prop-types";

export const DetailPanelContent = ({row}) => {
    return (
      <Stack
        sx={detailPanelContentStyle.outerStack}
        direction={"column"}
      >
        <Paper sx={detailPanelContentStyle.paper}>
          <Stack direction={"column"} spacing={2}>
            <Typography sx={detailPanelContentStyle.title}>Top content that uses this subject</Typography>
              { row?.topArticles?.length > 0 ? <TagTopNTable tagTopNData={row.topArticles} dateRange={row.dateRange}/>
              :
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography sx={detailPanelContentStyle.title}> 
                    No data currently available
                  </Typography>
                </Grid>
              </Grid>
              }
          </Stack>
        </Paper>
      </Stack>
    );
  }

DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  }