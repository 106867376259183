import React, { useState, useEffect } from "react";
import { Button, Snackbar } from "@mui/material";
import { notificationStyle } from "./NotificationStyle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { calculateNextTriggerTime, handleRefresh } from "./NotificationHelper";
import PropTypes from "prop-types";

export const stagingMessage = "Caution: This is a staging page. Data here is not suitable for reports."


export const Notification = ({ message, location }) => {
    const [open, setOpen] = useState(false);
    const [intervalId, setIntervalId] = useState(null);

    const handleClose = (event, reason) => {
        if (reason !== "clickaway") {
            setOpen(false);
        }
    };

    useEffect(() => {
        const timeUntilNextTrigger = calculateNextTriggerTime();

        const timeout = setTimeout(() => {
            setOpen(true);

            const id = setInterval(() => {
                setOpen(true);
            }, 900000); // 15 minutes

            setIntervalId(id);

        }, timeUntilNextTrigger);

        return () => {
            clearTimeout(timeout);
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    const notificationComponent = (
        <>
            <Button size={"small"} onClick={handleRefresh} sx={notificationStyle.button}>
                REFRESH
            </Button>
            <IconButton size={"small"} aria-label={"close"} onClick={handleClose} sx={notificationStyle.icon}>
                <CloseIcon fontSize={"small"} />
            </IconButton>
        </>
    );

    return (
        <Snackbar open={open}
                  onClose={handleClose}
                  message={message}
                  action={notificationComponent}
                  anchorOrigin={location}
        />
    );
};

Notification.defaultProps = {
    message: "New data may be available—refresh to check for updates.",
    location: { vertical: "bottom", horizontal: "center" }
};


Notification.propTypes = {
    message: PropTypes.string,   // Snackbar message
    location: PropTypes.shape({  // Snackbar location
        vertical: PropTypes.oneOf(["top", "bottom"]).isRequired,
        horizontal: PropTypes.oneOf(["left", "center", "right"]).isRequired
    })
};