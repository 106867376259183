import React from 'react';
import {Grid, Typography} from "@mui/material";
import {emptyMessage} from "./KPIGroupHelper"
import {KPISkeleton} from "../../../Loading/Skeleton/KPISkeleton/KPISkeleton";
import {KPI} from "../KPI";
import {StateCard} from "../../StateCard/StateCard";
import { PaperGrid } from '../../../Foundational/PaperGrid/PaperGrid';

export const KPIGroup = ({kpis, dataInfo, title}) => {
	if (dataInfo.loading || dataInfo.error) {
		return <StateCard loading={dataInfo.loading} error={dataInfo.error}
		                  component={<KPISkeleton num={kpis.length}/>}
		                  message={emptyMessage}/>
	} 
	return (
		<PaperGrid sxPaperOverride={{padding: 2}}>
			<Grid container direction={"column"} spacing={2}>
				{ title &&
				<Grid item>
					<Typography sx={{typography:"text.h6"}}>
						{title}
					</Typography>
				</Grid>
				}
				<Grid container item direction={"row"} justifyContent={"space-between"} spacing={2}>
					{kpis.map((kpi, index) => {
						return (
							<Grid container item xs={12.0 / kpis.length}>
								<KPI units={kpi.units} title={kpi.title} duration={kpi.duration}
									values={dataInfo.data[kpi.accessor]} tooltip={kpi.tooltip}/>
							</Grid>)
					})}
				</Grid>
			</Grid>
		</PaperGrid>
	)
}