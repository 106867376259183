export const embedsCardStyle = {
	paperOverride: {
		backgroundColor: "common.white",
		color: "common.black",
		padding: 2,
		maxWidth: "350px",
		height: "100%",

	},
	title: {
		"& .MuiBox-root": {
			typography: "text.chipSmall",
			paddingTop: 0.125
		},
		color: "common.black",
	},
	divider: {
		backgroundColor: "common.black",
		padding: 0,
		marginBottom: 1
	}
}