import React from 'react';
import {Box, Divider, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {renderContentDetailStyle} from "./RenderContentDetailStyle"
import {contentDetailStyle} from "../../../Cards/Graph/ContentDetail/ContentDetailStyle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {getURL} from "../../../Cards/Graph/ContentDetail/ContentDetailHelper";

const ContentDetailCell = React.memo(({content}) => {
	const url = getURL(content.type, content.contentId)
	return (
		<Box sx={renderContentDetailStyle.contentContainer}>
			<Grid container item direction={"row"} wrap={"nowrap"}>
				<Grid item container direction={"column"} alignItems={"flex-start"} justifyContent={"space-between"}>
					<Grid item>
						<Typography sx={renderContentDetailStyle.title} onClick={() => {
							window.open(url, '_blank').focus();
						}}>
							{content.title}
						</Typography>
					</Grid>
					<Grid container item direciton={"row"} alignItems={"flex-start"} justifyContent={"start"}>
						{content.type &&
						<>
							<Grid item>
								<Typography sx={renderContentDetailStyle.subTitle}>
									{content.type}
								</Typography>
							</Grid>
							<Divider sx={renderContentDetailStyle.divider} orientation={"vertical"} flexItem={true}/>
						</>
						}
						{content.timestamp &&
						<Grid item>
							<Typography sx={renderContentDetailStyle.subTitle}>
								Published: {content.timestamp + " ET"}
							</Typography>
						</Grid>}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
})

export const RenderContentDetail = (params) => (<ContentDetailCell content={params.value}/>)