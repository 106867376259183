import {createTheme} from "@material-ui/core/styles";
import {createTheme as createThemeV5} from '@mui/material/styles';

export const drawerWidthOpen = 256;
export const drawerWidthClosed = 73;

export const defaultPalette = {"Text": ["#FF3737", "#01012A"], "other": ["#09D8E8", "#01012A"]}
export const emptyStateSVGPalette = {
	img1: "#aeb8c2",
	img2: "#f5f5f7",
	img3: "#dce0e6",
	img4: "white",
	img5: {
		color: "#f5f5f5",
		opacity: "0.8"
	}
}
export const themeV5 = createThemeV5({
	palette: {
		primaryColour: {
			brand: "#FF3737",
			chip: "#C4000E",
			dropdown: "#ffbbae"
		},
		secondaryColour: {
			main: "#01012A",
		},
		tertiaryColour: {
			article: ["#FF3737", "#01012A"],
			video: ["#09D8E8", "#01012A"]
		},
		blueInfo:"#347694",
	},
	// Fonts
	typography: {
		text: {
			h5: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '1.375rem',
				fontWeight: 600
			},
			h6: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '1.25rem',
				fontWeight: 600
			},
			subtitle1: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '1rem',
				fontWeight: 600
			},
			subtitle2: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '1rem',
			},
			body1: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '.875rem',
				fontWeight: 600
			},
			body2: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '.875rem',
			},
			chipSmall: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '.8125rem',
			},
			tooltip: {
				fontFamily: ['Radio Canada', 'sans-serif'].join(','),
				fontSize: '.625rem',
				fontWeight: 600
			},
		},
		numeric: {
			h5: {
				fontFamily: ['Radio Canada', 'monospace'].join(','),
				fontSize: '1.5rem',
			},
			body2: {
				fontFamily: ['Radio Canada', 'monospace'].join(','),
				fontSize: '.875rem',
			},
			tooltip: {
				fontFamily: ['Radio Canada', 'monospace'].join(','),
				fontSize: '.625rem',
			},
		},
	}
})

var theme = createTheme({
	drawerWidthOpen: drawerWidthOpen,
	drawerWidthClosed: drawerWidthClosed,
	typography: {
		text: {
			h5: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '1.375rem',
				fontWeight: 600
			},
			h6: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '1.25rem',
				fontWeight: 600
			},
			subtitle1: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '1rem',
				fontWeight: 600
			},
			subtitle2: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '1rem',
			},
			body1: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '.875rem',
				fontWeight: 600
			},
			body2: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '.875rem',
			},
			chipSmall: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '.8125rem',
			},
			tooltip: {
				fontFamily: ['Open Sans', 'sans-serif'].join(','),
				fontSize: '.625rem',
				fontWeight: 600
			},
		},
		numeric: {
			h5: {
				fontFamily: ['Radio Canada', 'monospace'].join(','),
				fontSize: '1.5rem',
			},
			body2: {
				fontFamily: ['Radio Canada', 'monospace'].join(','),
				fontSize: '.875rem',
			},
			tooltip: {
				fontFamily: ['Radio Canada', 'monospace'].join(','),
				fontSize: '.625rem',
			},
		},
	},
	palette: {
		primaryColour: {
			brand: '#FF3737',
			chip: '#C4000E'
		},
		secondaryColour: {
			main: '#01012A',
		},
		tertiaryColour: {
			article: ['#FF3737', '#01012A'],
			video: ['#09D8E8', '#01012A']
		}
	},
})

export var theme = createTheme(theme, {
	tableToolbar: {
		'& .MuiTypography-root': {
			...theme.typography.text.body2,
		},

		'& .MuiInput-underline:after': {
			borderColor: theme.palette.primaryColour.brand,
		},
		'& .MuiInputLabel-root': {
			'&.Mui-focused': {
				color: theme.palette.primaryColour.brand
			},
		},

		'& .Mui-checked': {
			color: theme.palette.primaryColour.brand,
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.primaryColour.brand,
				opacity: 0.3,
			},
		},

		'& .MuiSwitch-track': {
			backgroundColor: theme.palette.grey[500],
		},

		'& .MuiButton-root': {
			color: theme.palette.primaryColour.brand,
		},
	}
},)