import React from "react";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {tablesStyle} from "../TablesStyle";
import { tagTopNColumns } from "./TagTopNTableHelper";
import { tagTopNTableStyle } from "./TagTopNTableStyle";
import PropTypes from "prop-types";
import { RenderDCADNavigation } from "../RenderCell/RenderDCADNavigation/RenderDCADNavigation";
import { getNav } from "../TablesHelper";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"

export const TagTopNTable = ({tagTopNData, dateRange}) => {
	const accessor = {
		type: "contentSubtype", contentId: "contentId", dateRange: dateRange
	}
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			sx={tagTopNTableStyle.table}
			columns={[{
				field: "Navigation",
				headerName: "",
				flex:1,
				valueGetter: getNav.bind(accessor),
				renderCell: RenderDCADNavigation,
				editable: false,
				disableColumnMenu: true,
				disableExport:true
			  },...tagTopNColumns]}
			rows={tagTopNData}
			getRowClassName={(params) =>
				`datagrid--${params.indexRelativeToCurrentPage % 2 === 0 ? "gray" : "white"}`
			}
			slots={{
				noRowsOverlay: CustomNoRowsOverlay
			}}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar},
			}}
			initialState={{
				sorting: {
					sortModel: [{field: "startTmstmp", sort: "asc"}],
				},
			}}
			columnHeaderHeight={56}
            getRowId={(row) => row.pageViews + "-" + row.contentHeadline}
			rowHeight={72}
			autoHeight={true}
            hideFooter={true}
		/>
	);
}

TagTopNTable.propTypes = {
	tagTopNData: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}