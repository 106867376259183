export const renderContentTitleStyle = {
	contentContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: "100%",
		position: "relative",
	},
	title: {
		typography: "text.body2",
	},
	subTitle: {
		typography: "text.body2"
	},
	divider: {
		margin: (0.5, 0.5, 0.5, 0.5),
	}
}