import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { SubjectRangeCard } from "../SubjectRangeCard/SubjectRangeCard"
import { SubjectGroupSkeleton } from "../../Loading/Skeleton/SubjectGroupSkeleton/SubjectGroupSkeleton"
import { StateCard } from "../StateCard/StateCard"
import { tagPercentileApi } from "../KPI/CCADGroup/CCADGroupHelper"
import { bottomAccessor, bottomText, bottomTitle, bottomZone, emptyMessage, topAccesssor, topText, topTitle, topZone } from "./SubjectGroupHelper"
import PropTypes from "prop-types";
export const SubjectGroup = ({dateRange, department, departments, mediaType, setEmpty, setHasError}) =>{

    const [dataInfo, setDataInfo] = useState({data: [], loading: true, error:false})
	useEffect(() => tagPercentileApi(dateRange, department, departments, setDataInfo, mediaType, setEmpty, setHasError), [dateRange, department, departments, mediaType])
    if (dataInfo.loading || dataInfo.error) {
		return <StateCard loading={dataInfo.loading} error={dataInfo.error}
		                  component={<SubjectGroupSkeleton/>}
		                  message={emptyMessage}/>
	}
    return(
        <Grid container item direction={"row"} spacing={3} wrap={"nowrap"}>
            <SubjectRangeCard title={topTitle} zone={topZone} low={dataInfo.data[topAccesssor][0]} high={dataInfo.data[topAccesssor][1]} text={topText}/>
            <SubjectRangeCard title={bottomTitle}  zone={bottomZone} low={dataInfo.data[bottomAccessor][0]} high={dataInfo.data[bottomAccessor][1]} text={bottomText} />
        </Grid>
    )
}

SubjectGroup.propTypes = {
	dateRange: PropTypes.array.isRequired,
	department: PropTypes.shape({
		id: PropTypes.string.isRequired,
		parent: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}).isRequired,
    departments: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		parent: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	mediaType: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}
