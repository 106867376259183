export const BRAND_COLOR = "#e60505";

export const BG_COLOR = "#fafafa";

export const TEXT_COLOR = "#000000";

export const TEXT_COLOR_LIGHT = "#999999";

export const TEXT_COLOR_SELECTED = "#437bd6";

export const CHART_FILL = "#e6e6e6";

export const CHART_NO_CHANGE = "#999999";

export const CHART_DOWN = "#e60d0a";

export const CHART_UP = "#30bf48";
