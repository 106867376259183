export const DCADStyle = {
	container: {
		paddingTop: 7,
		paddingX: 2,
		paddingBottom: 3
	},
	mail: {
		typography: "text.body2"
	},
	input: {
		mx: 1,
		my: 0.5,
		flex: 1,
	},
	paper: {
		paddingX: 0.5,
		minWidth: 310,
		display: "flex",
		alignItems: "center",
		width: "100%",
		border: 0,

	},
	button: {
		backgroundColor: "grey.300",
		color: "common.white",
		padding: 0.75,

		": hover": {
			backgroundColor: "secondaryColour.main",
		}
	}
}