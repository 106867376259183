import React from "react";
import {Dashboard} from "./components/v5/Pages/Dashboard/Dashboard";
import {NotFound} from "./components/v5/Pages/BodyContent/NotFound/NotFound";
import {Landing} from "./components/v5/Pages/BodyContent/Landing/Landing";
import {DCAD} from "./components/v5/Pages/BodyContent/DCAD/DCAD";
import {OCAD} from "./components/v5/Pages/BodyContent/OCAD/OCAD";
import { CCAD } from "./components/v5/Pages/BodyContent/CCAD/CCAD";
import { FAQGlossary } from "./components/v5/Pages/BodyContent/FAQGlossary/FAQGlossary";

const routes = [
	{
		path: "/",
		exact: true,
		component: <Dashboard children={<Landing/>}/>
	},
	{
		path: "/DCAD/:contentId",
		exact: true,
		component: <Dashboard children={<DCAD/>}/>
	},
	{
		path: "/OCAD",
		exact: true,
		component: <Dashboard children={<OCAD/>}/>
	},
	{
		path: "/CCAD",
		exact: true,
		component: <Dashboard children={<CCAD/>}/>
	},
	{
		path: "/faq-glossary",
		exact: true,
		component: <Dashboard children={<FAQGlossary/>}/>
	},
	{
		path: "*",
		component: <Dashboard children={<NotFound/>}/>
	}];

export default routes;
