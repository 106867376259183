export const landingStyle = {
	container: {
		marginX: "auto"
	},
	headerPaperOverride: {
		padding: 0,
		backgroundColor: "grey.50"
	},
	headerTitle: {
		"& .MuiTypography-root": {typography: "text.h5"}
	},
	headerCopy: {
		"& .MuiTypography-root": {typography: "text.body2"}
	},
	searchPaperOverride: {
		padding: 4,
		backgroundColor: "white",
		width: "100%"
	},
	searchButton: {
		color: "secondaryColour.main",
		borderColor: "secondaryColour.main",
		'&:hover': {
			backgroundColor: "secondaryColour.main",
			borderColor: "secondaryColour.main",
			color: "white"
		},
	},
	button: {
		display:"none",
	}
}