export const dashboardStyles = {
	box: {
		display: "flex",
		width: "100vw",
		height: "100vh",
		background: "grey.50",
		paddingBottom: 3
	},
	contentContainer: {
		height: "100%",
		minWidth: 275,
		backgroundColor: "grey.50",
		marginTop: 1,
		px: 2,
		pt: 10,
		pb: 1,
		...(theme) => (theme.mixin.toolbar),
	},
	formControl: {
		sx: {
			minWidth: 200,
			margin: 0,
			boxShadow: "none",
			".MuiOutlinedInput-notchedOutline": {border: 0, padding: 0},
			"& .MuiOutlinedInput-root": {
				"&.Mui-focused fieldset": {borderColor: "transparent"},
			},
		},
		size: "small"
	},
}