import React from "react";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {lineupTableStyle} from "./LineupTableStyle";
import {tablesStyle} from "../TablesStyle";
import PropTypes from "prop-types";
import {lineupColumns} from "./LineupTableHelper";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"


export const LineupTable = ({lineupData}) => {
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			columns={lineupColumns}
			rows={lineupData}
			getRowClassName={(params) =>
				`datagrid--${params.indexRelativeToCurrentPage % 2 === 0 ? "gray" : "white"}`
			}
			slots={{noRowsOverlay: CustomNoRowsOverlay}}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar},
			}}
			initialState={{
				pagination: {
					paginationModel: {pageSize: 10, page: 0},
				},
				sorting: {
					sortModel: [{field: "startTmstmp", sort: "asc"}],
				},
			}}
			pageSizeOptions={[10, 20, 50]}
			pagination
			columnHeaderHeight={38}
			rowHeight={lineupData == 0 ? 78 : 32}
			autoHeight={true}
			sx={lineupTableStyle.root}
		/>
	);
}

LineupTable.propTypes = {
	lineupData: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		lineup: PropTypes.string.isRequired,
		tileBucket: PropTypes.string.isRequired,
		lifeSpan: PropTypes.string.isRequired,
		lifeSpanSec: PropTypes.number.isRequired,
		startTmstmp: PropTypes.string.isRequired,
		endTmstmp: PropTypes.string.isRequired,
		isAlive: PropTypes.bool.isRequired
	})).isRequired
}