export const GlossaryCardStyle = {
    title:{typography: "text.h6", fontWeight:500, lineHeight:"2rem", letterSpacing:"0.01rem"},
    body: {typography: "text.body1", fontWeight:400, lineHeight:"1.5rem", letterSpacing:"0.01rem", color:"black", opacity:"0.6"},
    tagText: {typography:"text.chipSmall", fontWeight:500, lineHeight:"1.14rem", letterSpacing:"0.01rem", color:"black", opacity:"0.87"},
    tag:{typography:"text.chipSmall", fontWeight:500, lineHeight:"1.14rem", letterSpacing:"0.01rem", color:"black", opacity:"0.87"},
    paperOverride:{
        padding: 2,
        height: "100%",
        width: "100%",
    }
}