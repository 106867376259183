import React, {useState, useEffect} from "react";
import {LocalizationProvider, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import {DateRangeShortcuts} from "../DateRangeShortcuts/DateRangeShortcuts";
import {CoreDateRangePickerStyle} from "./CoreDateRangePickerStyle";
import { ClickAwayListener } from "@mui/base";
import {displayHelperText, onChangeAction, onClickHandler, onKeyDownHandler} from "../CoreDateRangePicker/CoreDateRangePickerHelper"
import Calendar from "@mui/icons-material/Event";
import {ActionBar} from "../ActionBar/ActionBar"
import PropTypes from "prop-types";
import {Typography, Grid} from "@mui/material"

export const CoreDateRangePicker = ({dateRange, setDateRange, minDate, storageKey, alert, setAlert, setHasError, setEmpty}) => {
	const [open, setOpen] = useState(false)
	const [helperText, setHelperText] = useState("")

	useEffect(()=>{
		if (!dateRange.validated[0] || !dateRange.validated[1]){
			setHelperText("")
		}
		setHelperText(displayHelperText(dateRange.validated[0], dateRange.validated[1]))

	}, [dateRange.validated])
	return (
		<Grid container item  alignItems={"center"} flexDirection={"row"} wrap={"nowrap"}>
			<ClickAwayListener onClickAway={() => {if (open){setOpen(false)}}}>
				<Grid item>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
					>
						<DateRangePicker
							value={dateRange.selected}
							disableFuture={true}
							closeOnSelect={false}
							open={open}
							minDate={minDate}
							format={"dd MMM yyyy"}
							slotProps={{
								...CoreDateRangePickerStyle.slotProps, actionBar: {
									alert: alert,
									setAlert: setAlert,
									dateRange: dateRange,
									storageKey:storageKey,
									setDateRange: setDateRange,
									setOpen: setOpen,
									setEmpty: setEmpty,
									setHasError: setHasError,
								},
								textField: {
									onClick: () => onClickHandler(setOpen),
									onKeyDown: (event) => onKeyDownHandler(event, setOpen),
									variant: "outlined", size: "small",
									...CoreDateRangePickerStyle.textField,
									InputProps: {endAdornment: <Calendar/>},
								},

							}}
							slots={{field: SingleInputDateRangeField, shortcuts: DateRangeShortcuts, actionBar: ActionBar}}
							onChange={onChangeAction(setDateRange, storageKey)}/>
					</LocalizationProvider>
				</Grid>
			</ClickAwayListener>
		</Grid>
	);
}

CoreDateRangePicker.propTypes = {
	dateRange: PropTypes.array.isRequired,
	minDate: PropTypes.array.isRequired,
	setDateRange: PropTypes.func.isRequired,
	storageKey: PropTypes.string.isRequired,
	setAlert: PropTypes.func.isRequired,
	alert: PropTypes.object.isRequired,
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}