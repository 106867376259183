import React, {useState, useEffect} from "react";
import {Container, Grid, Typography} from "@mui/material";
import {Header} from "../../../Header/Header";
import {CoreDateRangePicker} from "../../../Foundational/CoreDateRangePicker/CoreDateRangePicker";
import { CCADStyle } from './CCADStyle';
import { TagStatsCard } from '../../../Cards/TagStatsCard/TagStatsCard';
import { tagStatsTableColumnVisibilityModel } from './CCADHelper';
import { TagTitle } from '../../../Cards/TagTitle/TagTitle';
import { SubjectGroup } from '../../../Cards/SubjectGroup/SubjectGroup';
import { MediaTypeDropdown } from '../../../Foundational/MediaTypeDropdown/MediaTypeDropdown';
import { NotificationCard } from '../../../Cards/NotificationCard/NotificationCard';
import { errorTitle, errorMessage, emptyTitle, emptyMessage } from '../../Dashboard/DashboardHelper';
import { Notification } from "../../../Foundational/Notification/Notification";

const storageKey = "dateRangeCCAD"

export const CCAD = ({drawerOpen, dateRangeLowerBound, departmentFilter, department, departments}) => {
	const sessionDateRange = JSON.parse(sessionStorage.getItem(storageKey))
	const [dateRangeAlert, setDateRangeAlert] = useState({
		active: false,
		severity: "warning",
		body: "Sorry! Date ranges longer than 365 days cannot be processed right now."
	})
	const initialDateRange = sessionDateRange ? [new Date(sessionDateRange[0]),new Date(sessionDateRange[1])] : [new Date(),new Date()]
	const [minDate, setMinDate] = useState(dateRangeLowerBound)
	const [dateRange, setDateRange] = useState({
		selected: initialDateRange, validated: initialDateRange
	})

	const [anyChildHasError, setAnyChildHasError] = useState(false)
	const [anyChildIsEmpty, setAnyChildIsEmpty] = useState(false)
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(tagStatsTableColumnVisibilityModel)


	const [media, setMedia] = useState({id: "All Media", name: "All Content Types"})
	const mediaFilter = <MediaTypeDropdown media={media} handleMediaChange={(mediaObject) => {
			setMedia(mediaObject)
		}}/>

		useEffect(()=>{
			setAnyChildHasError(false)
			setAnyChildIsEmpty(false)
		}, [department, media])

	return (<>
		<Header drawerOpen={drawerOpen}
		        datePicker={<CoreDateRangePicker dateRange={dateRange} setDateRange={setDateRange}
		                                         alert={dateRangeAlert}
		                                         setAlert={setDateRangeAlert}
		                                         minDate={minDate} storageKey={storageKey}
												 setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}
												 />}
		        alert={dateRangeAlert}
		        setAlert={setDateRangeAlert}
		        dropdown={[mediaFilter, departmentFilter]}
		/>

		<Container sx={CCADStyle.container}>
			<Grid container direction={"column"} spacing={3}>
			<NotificationCard
							title={errorTitle}
							message={errorMessage}
							severity={"error"}
							open={false}
							handleClose={()=>setAnyChildHasError(false)}
							/>
						<NotificationCard
							title={emptyTitle}
							message={emptyMessage}
							severity={"info"}
							open={false}
							handleClose={()=>setAnyChildIsEmpty(false)}
							/>
				<TagTitle department={department} departments={departments} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
				<SubjectGroup dateRange={dateRange.validated} department={department} departments={departments} mediaType={media} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
				<TagStatsCard  dateRange={dateRange.validated} department={department} departments={departments} mediaType={media} columnVisibilityModel={columnVisibilityModel} setColumnVisibilityModel={setColumnVisibilityModel} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
			</Grid>
		</Container>
		<Notification/>
	</>)
}