import {drawerWidthClosed, drawerWidthOpen} from "../../../../../common/styles";

export const userDrawerListItemButtonStyle = (drawerOpen) => ({
	avatar: {
		backgroundColor: "common.white",
		color: "common.black",
		width: "24px",
		height: "24px",
		display: drawerOpen ? "none" : "inherit",
	},
	avatarBasic: { backgroundColor: "common.white", color: "common.black", },
	chip : {
		display: drawerOpen ? "inherit" : "none",
		width: drawerWidthOpen - drawerWidthClosed,
		color: "common.white",
		borderColor: "common.white",
		"& .MuiChip-label": (theme) => (theme.typography.text.body1),
		"&:hover ": { color: "common.black", borderColor: "common.black", },
		"&:focus ": { color: "common.black", borderColor: "common.black", },
	},
})