import React from "react";
import {DataGridPremium,} from "@mui/x-data-grid-premium";
import {beforeAfterTableStyle} from "./BeforeAfterTableStyle";
import {beforeAfterTableColumns} from "./BeforeAfterTableHelper";
import {ColumnEditIcon, columnLocalText, TableToolbar} from "../TableToolbar/TableToolbar";
import {tablesStyle} from "../TablesStyle";
import { RenderDCADNavigation } from "../RenderCell/RenderDCADNavigation/RenderDCADNavigation";
import { getNav } from "../TablesHelper";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"

export const BeforeAfterTable = ({
	                                 beforeAfterData,
	                                 columnVisibilityModel,
	                                 setColumnVisibilityModel,
	                                 contentId,
	                                 dateRange,
									 augmentedAnalyticsData,
									 contentDetailData
                                 }) => {
	const accessor = { type: "contentSubtype", contentId: "contentId", dateRange: dateRange}
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			sx={beforeAfterTableStyle.dataGrid}
			columns={[{
				field: "Navigation",
				headerName: "",
				flex:1,
				valueGetter: getNav.bind(accessor),
				renderCell: RenderDCADNavigation,
				editable: false,
				disableColumnMenu: true,
				disableExport:true
			  },...beforeAfterTableColumns]}
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={(newModel) =>
				setColumnVisibilityModel(newModel)
			}
			rows={beforeAfterData}
			components={{
				Toolbar: TableToolbar,
				ColumnSelectorIcon: ColumnEditIcon,
				noRowsOverlay: CustomNoRowsOverlay
			}}
			getRowClassName={(params) =>
				`datagrid--${params.indexRelativeToCurrentPage % 2 === 0 ? "gray" : "white"}`
			}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar, placement: "bottom-end"},
				toolbar: {table: "BeforeAfter", contentId: contentId, dateRange: dateRange, pdfData:{tableData: beforeAfterData, augmentedAnalyticsData: augmentedAnalyticsData, contentDetailData:contentDetailData}}
			}}
			initialState={{
				pagination: {
					paginationModel: {pageSize: 10, page: 0},
				},
			}}
			pageSizeOptions={[10, 20, 50]}
			pagination
			columnHeaderHeight={38}
			rowHeight={80}
			autoHeight={true}
			localeText={{
				toolbarColumns: columnLocalText
			}}
		/>
	);
}