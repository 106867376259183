export const tagStatsTableStyle = {
	dataGrid: {
		"& .MuiDataGrid-columnHeaderTitle": {typography: "text.body1"},
		"& .MuiDataGrid-cell": {typography: "text.body2"},
		"& .numbers": {typography: "numeric.body2"},
		"& .MuiButton-root": {
			typography: "text.body1",
			color: "common.black"
		},
		maxWidth: "1022px"
	},
}