export const contentDetailStyle = {
	headline: {
		typography: "text.h5",
		":hover": {
			cursor: "pointer",
			textDecoration: "underline"
		}
	},
	time: {
		typography: "text.body2"
	},
	chip: {
		backgroundColor: "grey.200",
		typography: "text.chipSmall"
	},
	divider: {
		margin: (0.5, 0.5, 0.5, 0.5),
	},
	chipDivider: {
		marginTop: 1,
		marginX: 0.5,
		paddingLeft: 1
	},
	embed: {
		borderColor: "primaryColour.chip",
		backgroundColor: "primaryColour.chip",
		color: "common.white",
		": hover": {
			borderColor: "primaryColour.chip",
			backgroundColor: "primaryColour.chip",
			color: "common.white",
		}
	},
	avatar: {
		backgroundColor: "common.white",
		color: "primaryColour.chip"
	},
	hide: {
		display: "none"
	},
	iconButton: {
		color: "common.black"
	}
}