export const FAQGlossaryStyle = {
	container: {
		paddingTop: 7,
		paddingX: 2,
		paddingBottom: 3
	},
	glossaryTitle:{typography:"text.h5", lineHeight:"2rem", weight:500,  color:"black", opacity:"0.87", letterSpacing:"0.01rem"},
	glossaryTitleBox:{ 
		display: "flex", 
		justifyContent: "start", 
		width: "100%", 
		boxSizing: "border-box",
		},
		glossaryTitleGrid:{margin:"auto", width:"100%"}
}