export const RenderVideoEmbedChipStyle = {
	avatar: {
		backgroundColor: "common.white",
		color: "primaryColour.chip"
	},
	embed: {
		borderColor: "primaryColour.chip",
		backgroundColor: "primaryColour.chip",
		color: "common.white"
	},
}