export const drawerWidthOpen = 256;
export const drawerWidthClosed = 73;

export let drawerOpenStyle = {
	width: drawerWidthOpen,
	overflowX: "hidden",
	transition: (theme) => (theme.transitions.create(["width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}))
}

export let drawerClosedStyle = {
	width: drawerWidthClosed,
	overflowX: "hidden",
	transition: (theme) => (theme.transitions.create(["width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}))
}

export let iconStyle = {
	color: "common.white",
}

export let defaultDrawerListItemButtonStyle = {
	color: "common.white",
	width: "100%",
	paddingLeft: "16.5px",
	borderRadius: "8px",
	"& .MuiListItemText-primary": (theme) => (theme.typography.text.body1),
	"& .MuiSvgIcon-root": {
		color: "common.white",
	},
	"&: hover": {
		"& .MuiSvgIcon-root": {
			color: "common.black",
		},
		color: "common.black",
		backgroundColor: "common.white",
	},
	"&: focus":{
		"& .MuiSvgIcon-root": {
			color: "common.black",
		},
		color: "common.black",
		backgroundColor: "common.white",
	}

}

export let drawerHoverListUserButtonStyle = {
	"&: hover": {
		backgroundColor: "common.white",
		"& .MuiChip-root": {
			color: "common.black",
			borderColor: "common.black",
		},
		"& .MuiChip-avatar": {
			backgroundColor: "primaryColour.brand",
			color: "common.white",
		}
	},
	"&: focus": {
		backgroundColor: "common.white",
		"& .MuiChip-root": {
			color: "common.black",
			borderColor: "common.black",
		},
		"& .MuiChip-avatar": {
			backgroundColor: "primaryColour.brand",
			color: "common.white",
		}
	}
}