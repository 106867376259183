export const beforeAfterCardStyle = {
	title: {
		typography: "text.h6"
	},
	text: {
		typography: "text.subtitle2"
	},
	highlight: {
		color: "primaryColour.brand",
		fontWeight: 700
	},
	tab: {
		"& .MuiTab-root.Mui-selected": {
			color: "primaryColour.brand",
		},
	},
	tabIndicator: {
		backgroundColor: "primaryColour.brand",
	}
}