import React from "react";
import {LogoDrawerListItemButton} from "../ListItemButton/LogoDrawerListItemButton/LogoDrawerListItemButton";
import {
	StandardDrawerListItemButton
} from "../ListItemButton/StandardDrawerListItemButton/StandardDrawerListItemButton";
import { UserDrawerListItemButton} from "../ListItemButton/UserDrawerListItemButton/UserDrawerListItemButton";
import {AllInclusive as InfinityIcon, Assessment as AssessmentIcon, ExitToApp as LogoutIcon, HelpOutline as SupportIcon,
	LocalOffer as TagIcon, History as HistoryIcon} from "@mui/icons-material/";
import {iconStyle} from "../../../../common/styles";

export const logos = [
	{
		component: LogoDrawerListItemButton,
		name: "MiR-Analytics",
		path: `/`,
	},
]
export const routes = [
	{
		component: StandardDrawerListItemButton,
		name: "Detailed Insights",
		path: `/`,
		icon: <AssessmentIcon sx={{iconStyle}}/>
	},
	{
		component: StandardDrawerListItemButton,
		name: "Content Overview",
		path: `/OCAD`,
		icon: <InfinityIcon sx={{iconStyle}}/>,
	},
	{
		component: StandardDrawerListItemButton,
		name: "Subject Distribution",
		path: `/CCAD`,
		icon: <TagIcon sx={{iconStyle}}/>,
	}
];
export const settings = (userName) => [
	{
		component: UserDrawerListItemButton,
		name: userName,
		icon: <LogoutIcon sx={{iconStyle}}/>,
	},
	{
		component: StandardDrawerListItemButton,
		name: "FAQ and Glossary",
		path: "/faq-glossary",
		icon: <SupportIcon sx={{iconStyle}}/>,
	},
	{
		component: StandardDrawerListItemButton,
		name: "Release Notes",
		icon: <HistoryIcon sx={{iconStyle}}/>,
		disabled: true,
	},
];
