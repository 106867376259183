import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import {
  BRAND_COLOR,
  TEXT_COLOR_LIGHT,
  TEXT_COLOR_SELECTED
} from "../../common/colors";
import { BREAKPOINT_SM, BREAKPOINT_LG } from "../../common/breakpoints";

const Section = styled.section`
  margin: 0 0 50px 0;
  padding: 0 0 50px 0;
  border-bottom: 1px solid #000000;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

const H1 = styled.h1`
  margin: 0 0 50px 0;
  padding: 0;
  font-size: 25pt;
`;

const H2 = styled.h2`
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 20pt;
`;

const H3 = styled.h3`
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 15pt;
`;

const H4 = styled.h4`
  margin: 0 0 0 0;
  padding: 0;
  font-size: 15px;
`;

const P = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
`;

const Small = styled.div`
  margin: 0;
  padding: 0;
  color: ${TEXT_COLOR_LIGHT};
  font-size: 10pt;
`;

const Link = styled(({ children, to, href, ...props }) =>
  to ? (
    <RouterLink to={to} {...props}>
      {children}
    </RouterLink>
  ) : (
    <a href={href} {...props}>
      {children}
    </a>
  )
)`
  margin: 0;
  padding: 0;
  color: ${BRAND_COLOR};
  text-decoration: underline;
  cursor: pointer;
`;

const Table = styled.table`
  margin: 0;
  padding: 0;
  border-collapse: collapse;
`;

const TableBody = styled.tbody`
  margin: 0;
  padding: 0;
`;

const TableHeader = styled.thead`
  margin: 0;
  padding: 0;
`;

const TableFooter = styled.thead`
  margin: 0;
  padding: 0;
`;

const TableRow = styled.tr`
  margin: 0;
  padding: 0;

  ${({ clickable }) =>
    clickable
      ? `
        cursor: pointer;

        &:hover {
          td {
            background-color: #000000;
            color: #ffffff;

            svg text {
              fill: #ffffff;
            }
          }
        }
      `
      : ""}

  ${({ hide }) =>
    hide
      ? `
        display: none;
      `
      : ""}
`;

const WideRow = styled.tr`
  margin: 0;
  padding: 0;
  line-height: 36px;
  border-bottom: 1px solid #cccccc;
 `;

const TableColumnHeader = styled.th`
  margin: 0;
  padding: 10px 15px;
  color: ${TEXT_COLOR_LIGHT};
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  text-align: left;
  white-space: nowrap;

  &.sorted {
    color: ${TEXT_COLOR_SELECTED};
  }

  &.fixed {
    position: sticky;
    left: 0;
    background-color: #f9f9f9;
  }
`;

const TableColumn = styled.td`
  margin: 0;
  padding: 3px 15px;
  white-space: nowrap;

  &.fixed {
    position: sticky;
    left: 0;
    background-color: #f9f9f9;
  }

  ${({ spacer }) =>
    spacer
      ? `
        padding: 20px 0 0 0;
      `
      : ""}
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap || "wrap"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-content: center;
  align-items: ${({ alignItems }) => alignItems || "stretch"};
`;

const MetricsBox = styled(FlexBox)`
  justify-content: space-between;

  & > * {
    flex: 0 45%;
    margin-bottom: 40px !important;
  }

  ${BREAKPOINT_SM} {
    & > * {
      flex: 0 30%;
    }
  }

  ${BREAKPOINT_LG} {
    & > * {
      flex: 0 15%;
    }
  }
`;

export {
  Section,
  H1,
  H2,
  H3,
  H4,
  P,
  Small,
  Link,
  Table,
  TableBody,
  TableHeader,
  TableFooter,
  TableRow,
  WideRow,
  TableColumnHeader,
  TableColumn,
  FlexBox,
  MetricsBox
};
