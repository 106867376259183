import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Typography} from "@mui/material";
import {PaperGrid} from "../../../Foundational/PaperGrid/PaperGrid";
import {DateInfoCard} from "../../../Cards/Info/DateInfoCard/DateInfoCard";
import {VersionInfoCard} from "../../../Cards/Info/VersionInfoCard/VersionInfoCard";
import {SearchInput} from "../../../Foundational/SearchInput/SearchInput";
import {SearchInfoCard} from "../../../Cards/Info/SearchInfoCard/SearchInfoCard";
import {landingStyle} from "./LandingStyle";
import {Redirect} from "react-router-dom";

export const Landing = () => {
	useEffect(() => {
		document.title = "Landing";
		}, []);

	const [searchState, setSearchState] = useState("")
	const [redirect, setRedirect] = useState(false)

	if (redirect) {
		return (
			<Redirect to={"/DCAD/" + searchState}/>
		)
	}
	return (
		<Container sx={landingStyle.container}>
			<Grid container spacing={2} direction={"column"}>
				<PaperGrid elevation={0} sxPaperOverride={landingStyle.headerPaperOverride}>
					<Grid container item direction={"column"}>
						<Grid item sx={landingStyle.headerTitle}>
							<Typography align={"left"}>
								Search for CBC digital content
							</Typography>
						</Grid>
						<Grid item sx={landingStyle.headerCopy}>
							<Typography align={"left"}>
								Quickly discover content's engagement data, lineup history, and headline changes within
								a
								selected date range.
							</Typography>
						</Grid>
					</Grid>
				</PaperGrid>
				<PaperGrid elevation={1} sxPaperOverride={landingStyle.searchPaperOverride}>
					<Grid container item direction={"row"} justifyContent={"space-between"} spacing={2}>
						<Grid item xs={10}>
							<SearchInput placeholderText={"Search by Content ID or Media ID"}
							             sxOverride={{button: landingStyle.button}} action={formSubmit}/>
						</Grid>
						<Grid item xs={2}>
							<Button className={"landing-search-button"} type={"submit"} form={"searchInputForm"} variant={"outlined"}
							        sx={landingStyle.searchButton}>
								Search
							</Button>
						</Grid>
					</Grid>
				</PaperGrid>
				<SearchInfoCard/>
				<DateInfoCard/>
				<VersionInfoCard/>
			</Grid>
		</Container>
	)

	// ---------------------------------------------------------------------------
	function formSubmit(event) {
		if (event.target?.elements["0"]?.value) {
			setSearchState((event.target?.elements["0"]?.value || "").trim())
			setRedirect(true)
		}
	}
}
