import React, {useEffect, useState} from 'react';
import {StateCard} from "../StateCard/StateCard";
import {LargeTableSkeleton} from "../../Loading/Skeleton/LargeTableSkeleton/LargeTableSkeleton";
import {beforeAfterApi, contentDetailApi,  emptyMessage, tabMap, title} from "./BeforeAfterCardHelper";
import {beforeAfterCardStyle} from "./BeforeAfterCardStyle";
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";
import {Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import {BeforeAfterTable} from "../../Tables/BeforeAfterTable/BeforeAfterTable";
import PropTypes from "prop-types";

export const BeforeAfterCard = ({contentId, dateRange, columnVisibilityModel, setColumnVisibilityModel, setHasError, setEmpty}) => {
	const [dataInfo, setDataInfo] = useState({
		loading: true,
		error:false,
		data: {}
	})
	const [contentDetailData, setContentDetailData] = useState({})
	const [tab, setTab] = useState(0)

	useEffect(() => beforeAfterApi(contentId, dateRange, setDataInfo, setHasError, setEmpty), [dateRange, contentId]);
	useEffect(() => contentDetailApi(contentId, dateRange, setContentDetailData), [dateRange, contentId]);

	if (dataInfo.loading || dataInfo.error) {
		return (
			<StateCard loading={dataInfo.loading} error={dataInfo.error}
			           component={<LargeTableSkeleton/>}
			           message={emptyMessage}/>
		)
	}

	return (
		<PaperGrid>
			<Grid container item
			      direction={"column"}
			      justifyContent={"center"}
			      spacing={2}>
				<Grid item
				      align={"left"}>
					<Typography sx={beforeAfterCardStyle.title}>
						{title}
					</Typography>
				</Grid>
				<Grid container item direction={"column"} spacing={2}>
					{dataInfo.data[tabMap[tab]].augmentedAnalytics.categories !== "" && <Grid item align={"left"}>
						<Typography sx={beforeAfterCardStyle.text}>
							Audiences who engaged with this content are also interested in content
							about <Box sx={beforeAfterCardStyle.highlight}
							           display={"inline"}>{dataInfo.data[tabMap[tab]].augmentedAnalytics.categories}.</Box>
						</Typography>
					</Grid>}
					{dataInfo.data[tabMap[tab]].augmentedAnalytics.lineup !== "" && <Grid item align={"left"}>
						<Typography sx={beforeAfterCardStyle.text}>
							They highly frequented pages that include <Box sx={beforeAfterCardStyle.highlight}
							                                               display={"inline"}>{dataInfo.data[tabMap[tab]].augmentedAnalytics.lineup}.</Box>
						</Typography>
					</Grid>}
				</Grid>
				<Grid item align={"center"}>
					<Tabs
						value={tab}
						onChange={(event, newValue) => {
							setTab(newValue)
						}}
						sx={beforeAfterCardStyle.tab}
						TabIndicatorProps={{sx: beforeAfterCardStyle.tabIndicator}}
					>
						<Tab label={"Before"}/>
						<Tab label={"After"}/>
					</Tabs>
					<BeforeAfterTable beforeAfterData={dataInfo.data[tabMap[tab]].table}
									  augmentedAnalyticsData={dataInfo.data[tabMap[tab]].augmentedAnalytics}
					                  contentId={contentId}
					                  dateRange={dateRange}
					                  columnVisibilityModel={columnVisibilityModel}
					                  setColumnVisibilityModel={setColumnVisibilityModel}
									  contentDetailData={contentDetailData}
									  />
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

BeforeAfterCard.propTypes = {
	contentId: PropTypes.string.isRequired,
	dateRange: PropTypes.array.isRequired,
	columnVisibilityModel: PropTypes.object.isRequired,
	setColumnVisibilityModel: PropTypes.func.isRequired,
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}