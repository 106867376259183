import React, {useEffect, useState} from 'react';
import {StateCard} from "../../../v5/Cards/StateCard/StateCard";
import {SmallTableSkeleton} from "../../../v5/Loading/Skeleton/SmallTableSkeleton/SmallTableSkeleton";
import {PaperGrid} from "../../../v5/Foundational/PaperGrid/PaperGrid";
import {Grid, Typography} from "@mui/material";
import {HeadlineTable} from "../../../v5/Tables/HeadlineTable/HeadlineTable";
import {headlineCardStyle} from "./HeadlineCardStyle";
import {headlineApi} from "./HeadlineCardHelper";
import PropTypes from "prop-types";


const title = "Any headline changes?"
const emptyMessage = "Headline data currently unavailable"

export const HeadlineCard = ({dateRange, contentId, setHasError, setEmpty}) => {

	const [dataInfo, setDataInfo] = useState({loading: true, error:false, data: []})

	useEffect(() =>headlineApi(dateRange, contentId, setDataInfo, setHasError, setEmpty), [dateRange, contentId]);

	if (dataInfo.loading || dataInfo.error) {
		return (
			<StateCard loading={dataInfo.loading} error={dataInfo.error}
			           component={<SmallTableSkeleton/>} message={emptyMessage}/>
		)
	}

	return (
		<PaperGrid>
			<Grid container item direction="column" spacing={2}>
				<Grid item>
					<Typography sx={headlineCardStyle.headlineTitle} variant={"h6"}>
						{title}
					</Typography>
				</Grid>
				<Grid item>
					<HeadlineTable headlineData={dataInfo.data}/>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

HeadlineCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	contentId: PropTypes.string.isRequired,
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}