import React from 'react';
import {Box, Chip, Grid} from "@mui/material";
import {renderChipsStyle} from "./RenderChipsStyle";

const Chips = React.memo(({labels}) => (
	<Box sx={renderChipsStyle.chipContainer}>
		<Grid container direction={"row"} spacing={1} wrap={"nowrap"}>
			{labels.length && labels[0] !== "-1" ?
				<>
					{labels.map((label) => (
						<Grid item sx={renderChipsStyle.grid}>
							<Chip label={label} sx={renderChipsStyle.chip} size={"small"}/>
						</Grid>
					))}
				</>
				:
				<Grid item>
					<Chip label={"None"} sx={renderChipsStyle.emptyChip} size={"small"}/>
				</Grid>
			}
		</Grid>
	</Box>
))

export const RenderChips = (params) => {
	return (<Chips labels={params.value}/>)
}