export const topNContentPDFStyle= {
    paperOverride:{
        width: "auto",
        minWidth: "100%", 
        display: "block", 
        overflowX: "auto", 
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight:6
      },
      headerBox:{
        width: "13px",
        backgroundColor: "red",
        height: "100%", 
    },
	title: {
        typography:"text.h6",
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: "34.32px",
        letterSpacing:"0.15px"
        },
    header:{
            typography:"text.h6",
            fontSize: '1.5rem',
            fontWeight: 300,
            lineHeight: "34.32px",
            letterSpacing:"0.15px",
            paddingBottom:1.5
    },
    chipTitle:{
        typography:"text.body2",
        fontWeight: 700,
        lineHeight: "20.02px",
        letterSpacing:"0.15px",
        paddingRight:"0.25rem",
        paddingTop:0.25
    },
    spacingBox:{
        paddingY:2
    },
    horizontalSpacer:{
        paddingX:2
    },
    dataGrid:{
        marginLeft:7, 
        marginRight:7,
        paddingRight:4,
        "& .MuiDataGrid-columnHeaderTitle": { typography: "text.body1" },
        "& .MuiDataGrid-cell": { typography: "text.body2" },
        "& .numbers": { typography: "numeric.body2" },
        "& .MuiButton-root": {
            typography: "text.body1",
            color: "common.black"
        },
        "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
        },
        "& .MuiDataGrid-overlayWrapper": {
            position: "sticky",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 4,
        },
        width: "auto", 
        minWidth: "100%",
        "& .MuiDataGrid-main": {
            overflowX: "auto",
            width: "100%", 
        },
        "& .MuiDataGrid-root": {
            width: "100%", 
        },
    },
    footerText:{marginLeft:7, typography: "text.body2"},
	text: {
		typography: "text.subtitle2"
	},
	highlight: {
		color: "primaryColour.brand",
		fontWeight: 700
	},
	tab: {
		"& .MuiTab-root.Mui-selected": {
			color: "primaryColour.brand",
		},
	},
	tabIndicator: {
		backgroundColor: "primaryColour.brand",
	}
}