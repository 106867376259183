import React, {useEffect, useState} from "react";
import {Info} from "@mui/icons-material";
import {
	contentDetailApi,
	contentSummaryGraphApi,
	emptyGraphMessage,
	graphCardEmptyMessage,
	renderEmbedCards
} from "./GraphCardHelper";
import {defaultPalette} from "../../../../common/themes";
import {Box, Grid, Typography} from "@mui/material";
import {GraphOCADSkeleton} from "../../../v5/Loading/Skeleton/GraphOCADSkeleton/GraphOCADSkeleton";
import {StateCard} from "../../../v5/Cards/StateCard/StateCard";
import {PaperGrid} from "../../../v5/Foundational/PaperGrid/PaperGrid";
import {graphCardStyle} from "./GraphCardStyle";
import {ContentDetail} from "../../../v5/Cards/Graph/ContentDetail/ContentDetail";
import PropTypes from "prop-types";
import { StackedLineGraph } from "../../Charts/StackedLineGraph";
import { ChartOverlay } from "../../Charts/ChartOverlay/ChartOverlay";


export const GraphCard = ({dateRange, contentId, setHasError, setEmpty}) => {
	const [dataInfo, setDataInfo] = useState({
		graph: {data: [], loading: true, error:false, labels: "", empty:false},
		content: {
			data: {}, loading: true, error:false, overview: [], embed: [], embedVideo: [], embedSocialMedia: [],
			colourPalette: defaultPalette["Text"]
		}
	})

	useEffect(() => contentSummaryGraphApi(dateRange, contentId, setDataInfo, setHasError, setEmpty), [dateRange, contentId])
	useEffect(() => contentDetailApi(dateRange, contentId, setDataInfo, setHasError, setEmpty), [dateRange, contentId])

	let embedCards = renderEmbedCards(dataInfo)

	if (dataInfo.graph.loading || dataInfo.content.loading || dataInfo.content.error) {
		return (
			<StateCard loading={dataInfo.graph.loading || dataInfo.content.loading}
			           error={dataInfo.graph.error|| dataInfo.content.error}
			           component={<GraphOCADSkeleton/>}
			           message={graphCardEmptyMessage}/>
		)
	} else if (dataInfo.graph.error && !dataInfo.content.error) {
		return (
			<Grid container item direction={"column"} spacing={3} sx={graphCardStyle.graphCard}>
				<ContentDetail contentDetail={dataInfo.content.data} contentOverview={dataInfo.content.overview}
				               contentEmbeds={dataInfo.content.embed} embedCard={embedCards}/>
				<PaperGrid variant={"outlined"} sxPaperOverride={graphCardStyle.emptyGraphPaper}>
					<Grid container item spacing={1} justifyContent={"center"}>
						<Grid item>
							<Info sx={graphCardStyle.emptyGraphPaperText}/>
						</Grid>
						<Grid item>
							<Typography sx={graphCardStyle.emptyGraphPaperText}>
								{emptyGraphMessage}
							</Typography>
						</Grid>
					</Grid>
				</PaperGrid>
			</Grid>
		)
	}
	return (
		<Grid container item direction={"column"} sx={graphCardStyle.graphCard}>
			<Grid container item spacing={1} direction={"row"} justifyContent={"start"}>
				<ContentDetail contentDetail={dataInfo.content.data} contentOverview={dataInfo.content.overview}
				               contentEmbeds={dataInfo.content.embed}
				               embedCard={embedCards}/>
			</Grid>
			<Grid container item justifyContent={"center"} sx={graphCardStyle.hide}>
				<Grid container item direction={"row"} justifyContent={"end"}>
					{dataInfo.graph.labels.map((label, index) => (
						<Grid container item justifyContent={"end"} direction={"row"} wrap={"nowrap"}
						      key={label + index} xs={0.8}>
							<Box component={Grid} style={{
								...graphCardStyle.kpiLegend,
								...{backgroundColor: dataInfo.content.colourPalette[index]}
							}}
							/>
							<Grid item>
								<Typography sx={graphCardStyle.kpiTooltip}>
									{label}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
				<Grid item sx={graphCardStyle.chart}>
					<StackedLineGraph data={dataInfo.graph.data} isEmpty={dataInfo.graph.empty} groups={dataInfo.graph.labels}
					                 colourPalette={dataInfo.content.colourPalette}/>
									 {dataInfo.graph.empty && <ChartOverlay />}
				</Grid>
			</Grid>
		</Grid>
	);
}

GraphCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	contentId: PropTypes.string.isRequired
}