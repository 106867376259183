import {formatterHelp} from "../../../../common/formatters";
import {gridDateComparator, gridNumberComparator} from "@mui/x-data-grid-premium";
import { RenderTimeFormatter } from "../RenderCell/RenderTimeFormatter/RenderTimeFormatter";

const getDuration = (params) => formatterHelp("duration", params.value);

const getTimeFormatter = (params) => {
	let startTime, startTimeFormatted, options

	startTime = new Date(params.value)
	options = {year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};
	startTimeFormatted = startTime.toLocaleString("en-US", options)
	return startTimeFormatted;
}

const tileBucketSortComparator = (v1, v2, param1, param2) => {
	let value1 = v1.split(/,|>|-/).filter(Boolean).at(0)
	let value2 = v2.split(/,|>|-/).filter(Boolean).at(0)

	return gridNumberComparator(value1, value2, param1, param2)
}

export const lineupColumns = [
	{
		field: "startTmstmp",
		headerName: "Time of Promotion",
		valueFormatter: getTimeFormatter,
		flex: 1,
		editable: false,
		disableColumnMenu: true,
		sortComparator: gridDateComparator,
		cellClassName: "lineup-promotion"
	},
	{
		field: "lineup",
		headerName: "Lineup Name",
		flex: 2,
		editable: false,
		disableColumnMenu: true,
		cellClassName: "lineup-name"
	},
	{
		field: "tileBucket",
		headerName: "Card",
		flex: 0.5,
		editable: false,
		disableColumnMenu: true,
		sortComparator: tileBucketSortComparator,
		cellClassName: "lineup-numbers"
	},
	{
		field: "lifeSpanSec",
		headerName: "Duration",
		renderCell:RenderTimeFormatter,
		flex: 0.5,
		editable: false,
		disableColumnMenu: true,
	}
]