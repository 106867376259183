import React from 'react';
import {Box, Divider, Grid, Typography} from "@mui/material";
import {kpiStyle} from "./KPIStyle";
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";

export const KPI = ({title, values, duration, units, tooltip}) => {
	const valuesFiltered = Array.isArray(values) ? values : [values]
	const countupValues = valuesFiltered.map((value)=>{
		let suffix, end
		if (units === "time") {
			if (value < 100000) {
				end = value
				suffix = "s"
			} else {
				end = value * 1.0 / 60
				suffix = "min"
			}
		} else {
			end = value
			suffix = units ? units : ""
		}
		return {end: end, suffix:suffix}
	})
	return (
		<PaperGrid elevation={0} sxPaperOverride={kpiStyle.sxPaperOverride}>
			<Grid container item direction={"column"} justifyContent={"center"} sx={kpiStyle.grid}>
				<Grid container item direction={"row"} justifyContent={"start"} wrap={"nowrap"} spacing={1}>
					<Grid item>
						<Typography sx={kpiStyle.title}>
							{title}
						</Typography>
					</Grid>
					<Grid item sx={kpiStyle.icon}>
						{tooltip}
					</Grid>
				</Grid>
				<Divider/>
				<Grid container item wrap="nowrap" sx={kpiStyle.value} spacing={1}>
						{countupValues.map((value, index)=>{
							return(
								<>
								<Grid item>
									<CountUp end={value.end}
										duration={duration ? duration : 2}
										separator={","}
										decimals={0}
										suffix={value.suffix}
									/>
								</Grid>
								{index != countupValues.length - 1 &&
								<Grid item>
									<Typography sx={kpiStyle.separator}> - </Typography>
								</Grid>}
								</>
							)
						})}
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

KPI.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	duration: PropTypes.number,
	units: PropTypes.string.isRequired,
};