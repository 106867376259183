export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;

export const SITE_DESC = process.env.REACT_APP_SITE_DESC;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const GOOGLE_AUTH_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

export const ID_TOKEN_STORAGE = "id_token";

export const DATE_STORAGE = "date";

export const LOCAL_STORAGE = "local";

export const TABLETAB_STORAGE = "tabletab";

export const REFERRER_STORAGE = "referrer";

export const PLATFORM_STORAGE = "platform";

export const ENTRY_POINT_STORAGE = "entrypoint";

export const VISUALS_STORAGE = "visuals";