import {List} from "@mui/material";
import React from "react";

export const NavDrawerList = ({items, state = null, action = null, drawerOpen}) => {
	return (
		<List sx={{width: '100%'}}>
			{items.map((item, index) => {
					let ListButton = item.component
					return (
						<ListButton key={item.name + index}
						            item={item}
						            index={index}
						            drawerOpen={drawerOpen}
						            state={state}
						            action={action}
						            disabled={item.disabled ? true : false}/>
					)
				}
			)}
		</List>
	)
}