export const notFoundStyle = {
	grid: {height: "100%"},
	contained: {
		height: "100%",
		width: "100%",
		background: "grey.50"
	},
	text: {
		typography: "text.h5"
	}
}