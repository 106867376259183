import React from "react";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {headlineTableStyle} from "./HeadlineTableStyle";
import {headlineColumns} from "./HeadlineTableHelper";
import {tablesStyle} from "../TablesStyle";
import PropTypes from "prop-types";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"

export const HeadlineTable = ({headlineData}) => {
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			columns={headlineColumns}
			rows={headlineData}
			getRowClassName={(params) =>
				`datagrid--${params.indexRelativeToCurrentPage % 2 === 0 ? "gray" : "white"}`
			}
			slots={{noRowsOverlay: CustomNoRowsOverlay}}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar}
			}}
			initialState={{
				pagination: {
					paginationModel: {pageSize: 10, page: 0},
				},
				sorting: {
					sortModel: [{field: "timeOfChange", sort: "asc"}],
				},
			}}
			pageSizeOptions={[10, 20, 50]}
			pagination
			columnHeaderHeight={38}
			rowHeight={headlineData.length == 0 ? 78 : 32}
			autoHeight={true}
			sx={headlineTableStyle.root}
		/>
	);
}

HeadlineTable.propTypes = {
	headlineData: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		timeOfChange: PropTypes.string.isRequired,
		headlines: PropTypes.string.isRequired
	})).isRequired
}