import {Container, Grid, Typography} from "@mui/material";
import React from "react";
import {notFoundStyle} from "./NotFoundStyle";
import voidIllustration from "../../../../../images/void.svg"

export const NotFound = () => {
	const message = "Sorry! We can't find that page."
	return (
		<Container sx={notFoundStyle.contained
		}>
			<Grid container item spacing={2} direction={"column"} justifyContent={"center"} alignContent={"center"}
			      sx={notFoundStyle.grid}>
				<Grid item>
					<img src={voidIllustration} height={500} width={500}/>
				</Grid>
				<Grid item>
					<Typography textAlign={"center"} sx={notFoundStyle.text}>
						{message}
					</Typography>
				</Grid>
			</Grid>
		</Container>
	)
}