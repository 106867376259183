import { RenderCategoryString} from "../../../Tables/RenderCell/RenderCategoryString/RenderCategoryString";
import { RenderContentTitle } from "../../../Tables/RenderCell/RenderContentTitle/RenderContentTitle";
import {csvTimeFormatter, getContent, valueFormatter} from "../../../Tables/TablesHelper";
import {gridStringOrNumberComparator} from "@mui/x-data-grid-premium";
import { RenderTimeFormatter } from "../../../Tables/RenderCell/RenderTimeFormatter/RenderTimeFormatter";
export const dateOptions = {
    weekday: undefined,
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/New_York",
    timeZoneName: "short",
};
const percentageFormatter = (params) => (`${params.value}%`)
const accessor = {
	timestamp: "contentPublishTime",
	title: "contentHeadline",
	type: "contentType",
	contentId: "contentId"
}
export const beforeAfterPDFTableColumns = [
	{
		field: "contentInfo",
		headerName: "Content Title",
		disableColumnMenu: true,
		flex: 2,
		editable: false,
		valueGetter: getContent.bind(accessor),
		renderCell: RenderContentTitle,
		valueFormatter: ({value}) => (value.title + " - " + value.contentId),
		sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1.title, v2.title, param1, param2),
	},
	{
		field: "totalPageviewStreamed",
		headerName: "Pageviews, Streams",
		disableColumnMenu: true,
		flex: 0.5,
		editable: false,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
	},

		{
			field: "avgTimeSpent",
			headerName: "Average Time Spent",
			flex: 0.5,
			renderCell: RenderTimeFormatter,
			valueFormatter:csvTimeFormatter,
			editable: false,
			disableColumnMenu: true,
		},
	
		{
			field: "totalTimeSpent",
			headerName: "Total Time Spent",
			flex: 0.5,
			renderCell: RenderTimeFormatter,
			valueFormatter:csvTimeFormatter,
			editable: false,
			disableColumnMenu: true,
		},
        {
            field: "readWatchedPercentage",
            headerName: "%Read, %Watch",
            disableColumnMenu: true,
            flex: 0.5,
            editable: false,
            valueFormatter: percentageFormatter,
            cellClassName: "numbers",
        },
        {
            field: "totalReadWatched",
            headerName: "Total Read, Total Watch",
            disableColumnMenu: true,
            flex: 0.5,
            valueFormatter: valueFormatter,
            cellClassName: "numbers",
            editable: false
        },
    
        {
            field: "contentCategories",
            headerName: "Categories",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            editable: false,
            renderCell: RenderCategoryString,
        },
        {
            field: "percentage",
            headerName: "% Interactions",
            disableColumnMenu: true,
            flex: 0.3,
            editable: false,
            valueFormatter: percentageFormatter,
            cellClassName: "numbers",
        },
        {
            field: "contentType",
            headerName: "Content Type",
            disableColumnMenu: true,
            flex: 0.5,
            editable: false,
        },
    ]