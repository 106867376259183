import React from 'react';
import {FormControl, InputLabel, Select} from "@mui/material";
import {dropdownStyle} from "./DropdownStyle";
import {renderSelect} from "./DropdownHelper";
import PropTypes from "prop-types";

export const Dropdown = ({
	                         formControlProps, inputLabelProps, inputLabel, inputId, inputName, onChange, selectItems, customMenu
                         }) => {
	return (
		<FormControl sx={dropdownStyle.filter} variant="outlined" {...formControlProps}>
			<InputLabel id="Dropdown-Input-Label" {...inputLabelProps}>{inputLabel}</InputLabel>
			<Select
				labelId="Dropdown-Input-Label"
				id="Dropdown-Select"
				label={inputLabel}
				value={inputId}
				name={inputName}
				onChange={onChange}
				MenuProps={{sx: dropdownStyle.menuItem}}
			>
				{customMenu ? customMenu : renderSelect(selectItems)}
			</Select>
		</FormControl>
	)
}

Dropdown.propTypes = {
	formControlProps: PropTypes.object,
	inputLabelProps: PropTypes.object,
	inputLabel: PropTypes.string.isRequired,
	inputName: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	selectItems: PropTypes.array.isRequired,
	header: PropTypes.string,
}
