import React from 'react'
import {Grid, Skeleton} from "@mui/material";

export const GraphOCADSkeleton = () => {
	return (
		<Grid container item direction={"column"} spacing={2}>
			<Grid container item direction={"row"} justifyContent={"space-between"} spacing={1}>
				<Grid container item direction={"column"} xs={10}>
					<Grid item>
						<Skeleton width={"80%"}/>
					</Grid>
					<Grid item>
						<Skeleton width={"65%"}/>
					</Grid>
					<Grid item>
						<Skeleton width={"50%"}/>
					</Grid>
				</Grid>
				<Grid item xs={2}>
					<Skeleton variant={"rectangular"} width={"100%"} height={75}/>
				</Grid>
			</Grid>
			<Grid item>
				<Skeleton variant={"rectangular"} height={350}/>
			</Grid>
		</Grid>
	)
}