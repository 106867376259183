import {EmbedsCard} from "../../../Cards/Embed/EmbedsCard/EmbedsCard";
import {RenderVideoEmbeds} from "../../../Cards/Embed/RenderVideoEmbeds/RenderVideoEmbeds";
import {Videocam} from "@mui/icons-material";
import React from "react";
import {Avatar, Chip} from "@mui/material";
import {RenderVideoEmbedChipStyle} from "./RenderVideoEmbedChipStyle";
import {OnClickPopover} from "../../../Foundational/Utils/OnClickPopover/OnClickPopover";

export const RenderVideoEmbedChip = (params) => {
	const videos = params?.value?.embeddedVideo
	if (videos?.length) {
		const videoCount = videos.length
		const videoTitles = videos.map((video) => ({title: video.mediaTitle, id: video.mediaId, active: video.active}))
		const videoCard = <EmbedsCard
			renderEmbed={<RenderVideoEmbeds embedData={videoTitles}/>}
			icon={<Videocam/>}
			title={"Video Embeds"}
		/>
		const chip = <Chip
			label={"Video"}
			size={"small"}
			sx={RenderVideoEmbedChipStyle.embed}
			variant={"default"}
			avatar={<Avatar sx={RenderVideoEmbedChipStyle.avatar}>{videoCount}</Avatar>}
		/>
		return <OnClickPopover popoverBody={videoCard}>
			{chip}
		</OnClickPopover>
	}
	return <></>
}