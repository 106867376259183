import React from "react";
import { Button, Grid } from "@mui/material";
import { departmentDropdownStyle } from "./DepartmentDropdownStyle";

const handleClick = (value, handleDepartmentChange, handleClose) => {
    handleDepartmentChange(value)
    handleClose()
  };

 export const renderColumn = (items, handleDepartmentChange, handleClose) => {
      return(<>{items.map((item, index) => (
        <Grid item>
            <Button variant={"text"} size={"small"} key={index} onClick={() => handleClick(item, handleDepartmentChange, handleClose)} sx={departmentDropdownStyle.columnButton}>
            {item.name}
            </Button>
        </Grid>
      ))}</>)
  };