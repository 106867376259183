export const glossaryTerms = [
	{
	  title: "Average Time Spent",
	  body: "The total time spent engaging with a specific piece of content in the selected date range, divided by the total sessions.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Pageviews",
	  body: "The number of times specific story content is loaded.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Percentage interactions",
	  body: "Shares the percentage of all user sessions who engaged with specified content in their user journey before or after engaging with the selected content. This is measured by the number of times other content is consumed before or after the originally selected content, divided by the total number of content consumed across all user sessions before or after the selected content.",
	  tags: [
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Percentage read",
	  body: "The total number of reads divided by the total number of pageviews.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Percentage watch",
	  body: "The total number of watches divided by the total number of streams.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Read",
	  body: "Read counts involve a number of factors including scroll depth, reading speed and time spent.",
	  tags: [
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Session",
	  body: "Each time a user comes to the website or apps, and interacts with the content. If someone is inactive for 30 minutes, the session ends."
	},
	{
	  title: "Standard article",
	  body: "A story created using the story form in Polopoly.",
	  tags: [
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Streams",
	  body: "Total times that users pass the pre-roll ad to play video.",
	  tags: [
	    { label: "Content Overview", icon: "content" }
	  ]
	},
	{
	  title: "Total Pageviews",
	  body: "Total times a story's content is loaded.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" },
	    { label: "Subject Distribution", icon: "subject" }
	  ]
	},
	{
	  title: "Total Reads",
	  body: "Total times an article is read.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Total Sessions",
	  body: "Total times users initiate sessions on the website and apps.",
	  tags: [
	    { label: "Content Overview", icon: "content" }
	  ]
	},
	{
	  title: "Total Streams",
	  body: "The number of times a selected department’s video or audio content is loaded."
	},
	{
	  title: "Total Time Spent",
	  body: "The total engagement time spent by audiences with specific content across a selected date range.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Total Watches",
	  body: "The total times a video is watched.",
	  tags: [
	    { label: "Content Overview", icon: "content" },
	    { label: "Detailed Insights", icon: "detailed" }
	  ]
	},
	{
	  title: "Watch (live video)",
	  body: "For live videos, watch is when 60 seconds of playback has accumulated. Doesn't include pre-roll ads, buffering/stalls, pauses and mid-roll ads. The time resets after the visit/app session ends."
	},
	{
	  title: "Watch (On-demand video)",
	  body: "For on-demand videos, watch is counted once someone has passed the 75 per cent mark. This metric is only counted once per session regardless of whether someone moves the progress bar."
	},
	{
	  title: "Subjects",
	  body: "Specific topics or themes covered in content.",
	  tags: [
	    { label: "Subject Distribution", icon: "subject" }
	  ]
	},
	{
	  title: "Often Used With",
	  body: "Content that is frequently consumed together with the selected content.",
	  tags: [
	    { label: "Subject Distribution", icon: "subject" }
	  ]
	},
	{
	  title: "Pieces of Content",
	  body: "Individual items of content, such as articles or videos.",
	  tags: [
	    { label: "Subject Distribution", icon: "subject" }
	  ]
	},
	{
	  title: "Average Page Views",
	  body: "The average number of times pages are viewed within a specific period.",
	  tags: [
	    { label: "Subject Distribution", icon: "subject" }
	  ]
	}
];
