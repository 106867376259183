export const renderDCADNavigationStyle = {
    button: {
        backgroundColor: "grey.100",
        color: "palette.tertiaryColour.video",
        typography: "text.body1",
        textTransform: "none",
        "&:hover": {
            color: "#ffffff !important",
            backgroundColor: "secondaryColour.main",
        },
    }
}