export const renderChipsStyle = {
	chipContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: "100%",
		position: "relative",
	},
	grid: {
		paddingY: 0.75,
		paddingX: 0.75
	},
	chip: {
		backgroundColor: "primaryColour.chip",
		color: "common.white",
		typography: "text.chipSmall"
	},
	emptyChip: {
		backgroundColor: "grey.200",
		typography: "text.chipSmall"
	}
}