export const tablesStyle = {
	toolbar: {
		'& .MuiTypography-root': {typography: "text.body2"},
		'& .MuiInput-underline:after': {borderColor: "primaryColour.brand",},
		'& .MuiInputLabel-root': {
			'&.Mui-focused': {color: "primaryColour.brand"},
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: "primaryColour.brand",
			'& + .MuiSwitch-track': {
				backgroundColor: "primaryColour.brand",
				opacity: 0.3,
			},
		},
		'& .MuiSwitch-track': {backgroundColor: "grey.500"},
		'& .MuiButton-root': {color: "primaryColour.brand"},
		'& .MuiFormControl-root': {
			display: "none",
			visibility: "hidden"
		}
	}
}