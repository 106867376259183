import React from "react"
import {PaperGrid} from "../../../../v5/Foundational/PaperGrid/PaperGrid";
import {Box, Divider, Grid} from "@mui/material";
import {embedsCardStyle} from "./EmbedsCardStyle";


export const EmbedsCard = React.forwardRef(({renderEmbed, icon, title}, ref) => {
	return (
		<PaperGrid sxPaperOverride={embedsCardStyle.paperOverride} ref={ref}>
			<Grid container item direction={"row"} wrap={"nowrap"} spacing={1}>
				<Grid item>
					{icon}
				</Grid>
				<Grid item sx={embedsCardStyle.title}>
					<Box>
						{title}
					</Box>
				</Grid>
			</Grid>
			<Divider sx={embedsCardStyle.divider}/>
			<Grid container item direction={"column"} spacing={1}>
				{renderEmbed}
			</Grid>
		</PaperGrid>
	)
})