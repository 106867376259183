import React from 'react';
import {PaperGrid} from "../../../v5/Foundational/PaperGrid/PaperGrid";
import {Grid, LinearProgress, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import {stateCardStyle} from "./StateCardStyle";
import PropTypes from "prop-types";


export function StateCard({
	                          loading, empty, error, message,
	                          component = <LinearProgress sx={stateCardStyle.linearProgress}/>, errorComponent,
	                          sxPaperOverride, elevation = 0
                          }) {

	return (
		<PaperGrid
			elevation={elevation}
			sxPaperOverride={sxPaperOverride}>
			{loading && !error ?
				component : errorComponent ? errorComponent :
				<Grid container item spacing={1} justifyContent={"start"}>
					<Grid item>
						<InfoIcon sx={stateCardStyle.icon}/>
					</Grid>
					<Grid item sx={stateCardStyle.text}>
						<Typography>
							{message}
						</Typography>
					</Grid>
				</Grid>
			}
		</PaperGrid>
	)
}

StateCard.propTypes = {
	loading: PropTypes.bool.isRequired,
	empty: PropTypes.bool.isRequired,
	component: PropTypes.element,
	message: PropTypes.string.isRequired,
	styleProps: PropTypes.object,
	elevation: PropTypes.number
}