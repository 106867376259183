export const lineupTableStyle = {
	root: {
		'& .MuiDataGrid-columnHeaderTitle': {typography: "text.body1"},
		'& .MuiDataGrid-cell': {typography: "text.body2"},
		'& .datagrid--gray': {backgroundColor: "grey.200"},
		'& .datagrid--white': {backgroundColor: "common.white"},
		'& .lineup-promotion': {typography: "text.body2"},
		'& .lineup-name': {typography: "text.body2"},
		'& .lineup-duration': {typography: "numeric.body2"},
		'& .lineup-numbers': {typography: "numeric.body2"},
		maxWidth: "1022px"
	}
}