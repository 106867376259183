import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import { renderCategoryStringStyle } from "./RenderCategoryStringStyle";

const CategoryString = React.memo(({labels}) => (
	<Box sx={renderCategoryStringStyle.stringContainer}>
		<Grid container direction={"row"} spacing={1}>
			{labels.length && labels[0] !== "-1" ? 
            <Typography sx={renderCategoryStringStyle.string}>
                {labels.join(", ")}
            </Typography> : 
			<Typography sx={renderCategoryStringStyle.string}>
			None
			</Typography>
            }
		</Grid>
	</Box>
))

export const RenderCategoryString= (params) => {
	return (<CategoryString labels={params.value}/>)
}