import React, {useEffect, useState} from 'react';
import {KPIGroup} from "../KPIGroup/KPIGroup";
import {contentAnalyticsApi, generalKpis, textKpis, videoKpis} from "./DCADGroupHelper";


export const DCADGroup = ({dateRange, contentId, setHasError, setEmpty}) => {
	const [dataInfo, setDataInfo] = useState({data: [], loading: true, error: false})
	useEffect(() => contentAnalyticsApi(dateRange, contentId, setDataInfo, setHasError, setEmpty), [dateRange, contentId])
	const contentKpis = dataInfo.article ? textKpis : videoKpis
	const kpis = contentKpis.concat(generalKpis)
	return (
		<KPIGroup kpis={kpis} dataInfo={dataInfo}/>
	)
}