import {Chip, Typography} from "@mui/material";
import React from "react";
import { renderCommentStringStyle } from "./RenderCommentStringStyle";

export const RenderCommentString = (params) => {
    return(
        <Typography sx={renderCommentStringStyle.Typography}>
            {params?.value === "true" ? "Yes" : "No"}
        </Typography>
    )
}