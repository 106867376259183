export const illustrationProgressStyle = {
	overlay: {
		height: "100vh",
		width: "100vw",
		background: "grey.50"
	},
	contained: {
		height: "100%",
		width: "100%",
		background: "grey.50"
	},
	grid: {height: "100%"},
	linearProgress: {
		backgroundColor: "grey.50",
		"& .MuiLinearProgress-bar": {backgroundColor: "primaryColour.brand"},

	},
	text: {
		'& .Typography-root': (theme) => (theme.typography.text.body2)
	}
}