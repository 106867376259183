import React from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

export const CustomToggle = ({isExpanded}) => {
	return (
	  <IconButton
		size={"small"}
		tabIndex={-1}
		aria-label={isExpanded ? "Close" : "Open"}
	  >
		<ExpandMoreIcon
		  sx={{
			transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
			transition: (theme) =>
			  theme.transitions.create("transform", {
				duration: theme.transitions.duration.shortest,
			  }),
		  }}
		  fontSize={"inherit"}
		/>
	  </IconButton>
	);
  }

CustomToggle.propTypes = {
	isExpanded: PropTypes.bool.isRequired
}