export const shortcutFilter = (isValid, onChange) => {
	return (item) => {
		const newValue = item.getValue({isValid});
		return {
			label: item.label,
			onClick: () => {
				onChange(newValue);
			},
			disabled: !isValid(newValue),
		};
	}
}