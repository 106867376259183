import React, { useState } from "react";
import { Button, Menu, MenuItem} from "@mui/material";
import {GridToolbarExport, GridToolbarColumnsButton, GridToolbarContainer, useGridApiContext} from "@mui/x-data-grid-premium";
import {MoreVert} from "@mui/icons-material";
import {tableToolbarStyle} from "./TableToolbarStyle";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { exportPDF } from "./TableToolbarHelper";
import { TopNContentPDF } from "../../Cards/ExportPDFCard/TopNContentPDF/TopNContentPDF";
import { BeforeAfterPDF } from "../../Cards/ExportPDFCard/BeforeAfterPDF/BeforeAfterPDF";

export const TableToolbar = ({table, contentId, dateRange, department, pdfData}) => {
	const apiRef = useGridApiContext()
	const page = window.location.pathname.split("/")[1]
	const startDate = new Date(dateRange[0]).toDateString()
	const endDate = new Date(dateRange[1]).toDateString()
	const idInsert = contentId ? `-${contentId}-` : "-"
	const fileName = `MIR-${page}-${table}` + idInsert + `${startDate}-${endDate}`
	const pdf = table === "BeforeAfter" ? 
		<BeforeAfterPDF contentId={contentId} startDate={startDate} endDate={endDate} augmentedAnalyticsData={pdfData.augmentedAnalyticsData} tableData= {pdfData.tableData} contentDetailData={pdfData.contentDetailData}/> : 
		table === "TopContentPerformers" ?
		<TopNContentPDF startDate={startDate} endDate={endDate} department={department} platform={pdfData.platform} contentType={pdfData.contentType} tableData={pdfData.tableData}/> :
		null
	const [anchorMenu, setAnchorMenu] = useState(null)
	const open = Boolean(anchorMenu);
	const handleClick = (event) => {
		  setAnchorMenu(event.currentTarget);
		};
	const handleClose = () => {
		setAnchorMenu(null);
		};

	return (
		<GridToolbarContainer sx={tableToolbarStyle.toolbarContainer}>
			{pdf? 
			<>
				<Button
					startIcon={<SystemUpdateAltIcon />}
					id={"export-menu"}
					aria-controls={open ? "export-menu" : undefined}
					aria-haspopup={"true"}
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					Export
				</Button>
				<Menu
					id={"export-menu"}
					anchorEl={anchorMenu}
					open={open}
					onClose={handleClose}
					MenuListProps={{
					"aria-labelledby": "export-button",
					}}
				>
					<MenuItem onClick={()=>exportPDF(fileName + ".pdf", pdf)}>Export .PDF</MenuItem>
					<MenuItem onClick={()=>apiRef.current.exportDataAsCsv({fileName: fileName})}>Export .CSV</MenuItem>
				</Menu>
			</>
			:
			<GridToolbarExport
				csvOptions={{
					fileName: fileName,
				}}
				excelOptions={{
					fileName: fileName,
				}}
			/>
		}
			<GridToolbarColumnsButton/>
		</GridToolbarContainer>
	)
}
export const ColumnEditIcon = () => (<MoreVert/>)

export const columnLocalText = "ADD METRICS"