export const topNTableColumnVisibilityModel = {
	navigation:true,
	headline: true,
	categories: false,
	embeddedMedia: false,
	commentFlag: false,
	totalPageviewStreamed: true,
	totalReadWatched: false,
	readWatchedPercentage: true,
	avgTimeSpent: true,
	totalTimeSpent: true
}