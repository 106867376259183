export const graphCardStyle = {
	chart: {
		width: "100%",
		padding: 2,
		position:"relative"
	},
	paper: {
		padding: 3,
		margin: "auto",
		backgroundColor: "grey.200",
		width: "100%",
		height: "100%"
	},
	graphCard: {
		maxWidth: "100%",
		marginTop: 4
	},
	emptyGraphPaper: {
		borderColor: "grey.500",
		borderWidth: "thin",
		padding: 2,
	},
	emptyGraphPaperText: {
		color: "grey.500"
	},
	kpiTitle: {
		typography: "text.body2",
		fontWeight: 400
	},
	kpiTooltip: {
		typography: "text.tooltip",
		paddingLeft: 0.5,
		fontWeight: 400
	},
	kpiLegend: {
		width: 14,
		height: 15,
		borderRadius: 3
	},
	hide: {
		[(theme) => theme.breakpoints.down("sm")]: {
			display: "none",
		},
	}
}