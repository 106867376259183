import React, {useState} from "react";
import {onClickPopoverStyle} from "./OnClickPopoverStyle";
import {Popover} from "@mui/material";

export function OnClickPopover({children, popoverBody}) {
	let [anchor, setAnchor] = useState(null)
	let open = Boolean(anchor)
	return (
		<>
			{React.cloneElement(children, {
				onClick: handlePopoverOpen,
			})}
			<Popover
				open={open}
				anchorEl={anchor}
				sx={onClickPopoverStyle.popover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
			>
				{popoverBody}
			</Popover>
		</>
	)

	// *********************************************************************
	function handlePopoverOpen(event) {
		setAnchor(event.currentTarget)
	}

	function handlePopoverClose(event) {
		setAnchor(null)
	}
}