import React from "react"
import { PaperGrid } from "../../Foundational/PaperGrid/PaperGrid"
import { Grid, Typography, Box } from "@mui/material"
import { subjectRangeCardStyle } from "./SubjectRangeCardStyle"
import CountUp from 'react-countup';
import PropTypes from "prop-types";
export const SubjectRangeCard = ({zone, title, text, low, high}) => {
    return (
        <PaperGrid sxPaperOverride={subjectRangeCardStyle.sxPaperOverride}>
            <Grid container item direction={"column"} spacing={2}>
                <Grid item>
                    <Box display={"inline-block"} sx={zone === "top" ? subjectRangeCardStyle.zoneBoxTop : subjectRangeCardStyle.zoneBoxBottom}>
                        <Typography sx={subjectRangeCardStyle.zoneName}>
                            {zone.charAt(0).toUpperCase() + zone.slice(1)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                        <Typography sx={subjectRangeCardStyle.title}>
                            {title}
                        </Typography>
                </Grid>
				<Grid container item wrap="nowrap" spacing={1} sx={subjectRangeCardStyle.value}>
								<Grid item>
									<CountUp end={low}
										duration={2}
										separator={","}
										decimals={0}
									/>
								</Grid>
								<Grid item sx={subjectRangeCardStyle.separator}>
									<Typography> - </Typography>
								</Grid>
                                <Grid item>
									<CountUp end={high}
										duration={2}
										separator={","}
										decimals={0}
									/>
								</Grid>
				    </Grid>
                <Grid item>
                    <Typography sx={subjectRangeCardStyle.text}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </PaperGrid>
    )
} 

SubjectRangeCard.propTypes = {
zone:PropTypes.string,
title:PropTypes.string,
text:PropTypes.string,
low:PropTypes.number,
high:PropTypes.number,
}