export const searchInfoCardStyle = {
	paperOverride: {
		borderColor: "grey.400",
		borderWidth: "thin",
		width: "100%",
		height: "100%",
		margin: "auto",
		padding: 2
	},
	text: {
		"& .MuiTypography-root": {typography: "text.body2"},
		paddingBottom: 3
	},
	image: {
		width: "100%", height: "100%", paddingLeft: 5
	},
	icon: {color: "grey.400"},
}