import React from 'react';
import {Grid, Paper} from "@mui/material";
import {paperGridStyle} from "./PaperGridStyle";
import PropTypes from "prop-types";


export const PaperGrid = React.forwardRef(({children, variant, elevation = 0, sxPaperOverride}, ref) => {
	return (
		<Grid container item ref={ref}>
			<Paper variant={variant} elevation={elevation}
			       sx={sxPaperOverride ? {...paperGridStyle, ...sxPaperOverride} : paperGridStyle}>
				{children}
			</Paper>
		</Grid>
	)
})

PaperGrid.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	variant: PropTypes.string,
	elevation: PropTypes.number,
	styleProps: PropTypes.object,
}