const smallBreak = 600;

export const lineupCardStyle = ({
	lineupTitle: {
		typography: "text.h6",
	},
	formControl: {
		margin: 0,
		width: "25ch",
	},
	lineupDropdownItem: {
		[(theme) => theme.breakpoints.down(smallBreak)]: {
			display: "none"
		},
	},
})