export const tagStatsTableColumnVisibilityModel = {
	tag:true,
    numContent:true,
    loads:false,
    streams:false,
    pageViews:true,
    averagePageViews:true,
    tagPopularity:false,
    tagUtilization:false,
    navigation:true
}

export const mediaTypes = [{id: "All Media", name: "All Content Types"}, {id: "Live Video", name: "Live Video"},
	{id: "On-Demand Video", name: "On-Demand Video"}, {id: "Standard Article", name: "Standard Article"}]