import {getFormattedDate} from "../../../../../common/formatters";
import apiCall from "../../../../../common/apiCall";

const article = "Text"

export const emptyMessage = "KPI Metrics currently unavailable"

export const generalKpis = [
	{
		title: "Total Time Spent",
		accessor: "totalTimeSpent",
		duration: 2,
		units: "time",
	},
	{
		title: "Average Time Spent",
		accessor: "avgTimeSpent",
		duration: 2.5,
		units: "time",
	}
];

export const textKpis = [
	{
		title: "Total Pageviews",
		accessor: "totalPageviews",
		duration: 0.5,
		units: null,
	},
	{
		title: "Total Read",
		accessor: "totalRead",
		duration: 1,
		units: null,
	},
	{
		title: "% Read",
		accessor: "readPercentage",
		duration: 1.5,
		units: "%",
	},
]

export const videoKpis = [
	{
		title: "Total Streams",
		accessor: "totalStreams",
		duration: 0.5,
		units: null,
	},
	{
		title: "Total Watched",
		accessor: "totalWatched",
		duration: 1,
		units: null,
	},
	{
		title: "% Watched",
		accessor: "watchedPercentage",
		duration: 1.5,
		units: "%",
	},
]

export const contentAnalyticsApi = (dateRange, contentId, setDataInfo, setHasError, setEmpty) => {

	setDataInfo((prevState) => ({...prevState, loading: true, error: false}))

	let startDate = getFormattedDate(dateRange[0]),
		endDate = getFormattedDate(dateRange[1]),
		query = `
	            query dcadGraphAugmentedAnalytics($startDate: String!, $endDate: String!, $contentId: String!) {
	                dcadGraphAugmentedAnalytics(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
						contentSubtype
						contentType
	                    totalTimeSpent
	                    avgTimeSpent
	                    totalRead
	                    totalWatched
	                    readPercentage
	                    watchedPercentage
	                    totalPageviews
	                    totalStreams
	                }
	            }
	        `;

	const variables = {startDate: startDate, endDate: endDate, contentId: contentId};

	apiCall(query, variables)
		.then(response => {
			const {dcadGraphAugmentedAnalytics: dcadGraphAugmentedAnalyticsData} = response;
			if (Object.keys(dcadGraphAugmentedAnalyticsData).length === 0) {
				const kpis = generalKpis.concat(textKpis).concat(videoKpis)
				setEmpty(true)
				setDataInfo({dcadGraphAugmentedAnalyticsData: kpis.map((kpi)=>{
					return{'${kpi.accessor}':0}
				}), loading: false, error:false})
			} else {
			setDataInfo({
				data: dcadGraphAugmentedAnalyticsData,
				loading: false,
				error:false,
				article: dcadGraphAugmentedAnalyticsData.contentType === article
			})
		}
		})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: false,  error:true}))
			setHasError(true)
			console.log({contentAnalyticsError: error});
		});
}